import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ReactComponent as Logo } from './logo.svg';
import Navigation from './navigation';
import LoggedOutState from './loggedout'
import Cases from './cases';
import Dashboard from './dashboard';
import Resources from './resources';
import Reporting from './reporting';
import Administration from './administration';
import Notifications from './notifications';
import Search from './search';
import SearchResults from './search_results';
import CaseApp from './case';
import CreateCase from './createcase';
import NotesApp from './notes';
import Footer from './footer';
import { HandleLogin } from './loginout';
import IRDisplay from './ir_display';
import { getUID, getUser } from './utilities';
// import reportWebVitals from './reportWebVitals';


import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";



function USCSSLogo() {
  return (
    <a href="/"><div id="logo">
      <Logo />
    </div></a>
  );
}

// give a think to Redux or some variant thereof — check Dispatch
// manage login state prior to render
// check with Arash to see if we can create a separate login view

let uid = getUID();
let userDetails = getUser(uid);
if(!uid && document.location.pathname !== "/"){
  document.location.href = "/";
}


if(uid){
  ReactDOM.render(
    <React.StrictMode>
      <Router>
          <Switch>
            <Route path="/case/create">
              <nav id="nav">
                <USCSSLogo />
                <Navigation role={ userDetails.role } />
              </nav>
              <Search />
              <CreateCase />
              <Footer />
            </Route>
            <Route path="/case/ir:id">
              <nav id="nav">
                <USCSSLogo />
                <Navigation role={ userDetails.role } />
              </nav>
              <Search />
              <IRDisplay />
              <Footer />
            </Route>
            <Route path="/case/:id">
              <nav id="nav">
                <USCSSLogo />
                <Navigation role={ userDetails.role } />
              </nav>
              <Search />
              <CaseApp />
              <Footer />
            </Route>
            <Route path="/notes/:id">
              <NotesApp />
            </Route>
            <Route path="/cases">
              <nav id="nav">
              <USCSSLogo />
              <Navigation role={ userDetails.role } active="Cases"/>
              </nav>
              <Search />
              <Cases />
              <Footer />
            </Route>
            <Route exact path="/dashboard">
              <nav id="nav">
                <USCSSLogo />
                <Navigation role={ userDetails.role } active="Dashboard"/>
              </nav>
              <Search />
              <Dashboard userDetails={ userDetails } />
              <Footer />
            </Route>
            <Route exact path="/reporting">
              <nav id="nav">
              <USCSSLogo />
              <Navigation role={ userDetails.role } active="Reporting"/>
              </nav>
              <Search />
              <Reporting />
              <Footer />
            </Route>
            <Route exact path="/resources">
              <nav id="nav">
              <USCSSLogo />
              <Navigation role={ userDetails.role } active="Resources"/>
              </nav>
              <Search />
              <Resources />
              <Footer />
            </Route>
            <Route exact path="/administration">
              <nav id="nav">
              <USCSSLogo />
              <Navigation role={ userDetails.role } active="Administration"/>
              </nav>
              <Search />
              <Administration />
              <Footer />
            </Route>
            <Route exact path="/notifications">
              <nav id="nav">
              <USCSSLogo />
              <Navigation role={ userDetails.role } />
              </nav>
              <Search />
              <Notifications />
              <Footer />
            </Route>
            <Route exact path="/search">
              <nav id="nav">
                <USCSSLogo />
                <Navigation role={ userDetails.role }/>
              </nav>
              <Search />
              <SearchResults />
              <Footer />
            </Route>
            <Route exact path="/login">
              <HandleLogin />
            </Route>
            <Route exact path="/">
              <LoggedOutState />
            </Route>
          </Switch>
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );
}
else{ // the only options available to a non-logged-in user…
  ReactDOM.render(
    <React.StrictMode>
      <Router>
          <Switch>
            <Route exact path="/login">
              <HandleLogin />
            </Route>
            <Route exact path="/">
              <LoggedOutState />
            </Route>
          </Switch>
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );
}









// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
