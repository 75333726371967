import { useState } from "react";

function WidgetTeamCases(props:any){

  let teamCaseAssignments = [
     { "case_number": "IR2021-9302", "respondent_name": "Ryleigh Krause", "assigned_to": "You", "last_updated": "This morning", "sla_remaining": "1 day" },
     { "case_number": "IR2021-7212", "respondent_name": "Olivia Navarro", "assigned_to": "Carrie Anotname", "last_updated": "Yesterday", "sla_remaining": "2 days" },
     { "case_number": "IR2021-8743", "respondent_name": "Turner Winters", "assigned_to": "Anon Ymous", "last_updated": "Yesterday", "sla_remaining": "2 days" },
     { "case_number": "IR2021-6699", "respondent_name": "Mauricio Klein", "assigned_to": "Carrie Anotname", "last_updated": "This morning", "sla_remaining": "2 days" },
     { "case_number": "IR2021-7112", "respondent_name": "Quentin Morton", "assigned_to": "Enelis Bevardo", "last_updated": "Last week", "sla_remaining": "4 days" },
     { "case_number": "IR2021-7402", "respondent_name": "Arnav Carrillo", "assigned_to": "You", "last_updated": "This morning", "sla_remaining": "5 days" },
     { "case_number": "IR2021-7323", "respondent_name": "Randy Park", "assigned_to": "Wario Senzanome", "last_updated": "Yesterday", "sla_remaining": "5 days" },
     { "case_number": "IR2021-7324", "respondent_name": "Hugo Lester", "assigned_to": "Agustí Sensenom", "last_updated": "Tuesday", "sla_remaining": "8 days" },
     { "case_number": "IR2021-7332", "respondent_name": "Bejorne Melustra", "assigned_to": "Enelis Bevardo", "last_updated": "This morning", "sla_remaining": "9 days" },
     { "case_number": "IR2021-7300", "respondent_name": "Mahesh Ravi", "assigned_to": "Enelis Bevardo", "last_updated": "Last week", "sla_remaining": "9 days" }
   ];

  return(
    <div className="widgetWrapper fullWidth">
      <div className="wrapperHeader">Intake Team Cases</div>

      <table className="dashboard_table widget hasAction">
        <thead>
          <tr>
            <th>Case #</th>
            <th>Respondent</th>
            <th>Assigned To</th>
            <th>Last Updated</th>
            <th>SLA Remaining</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          { teamCaseAssignments.map( (c, i) => <tr key={i} className={ c.sla_remaining === "1 day" || c.sla_remaining === "2 days" ? "atrisk" : "" }>
            <td>{ c.case_number }</td>
            <td>{ c.respondent_name }</td>
            <td>{ c.assigned_to }</td>
            <td>{ c.last_updated }</td>
            <td>{ c.sla_remaining }</td>
            <td></td>
          </tr>)}
        </tbody>
      </table>
    </div>
  )
}


export default WidgetTeamCases;
