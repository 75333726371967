import React, { useState } from 'react';
import { caseEditTypes } from './utilities';



function MakeDataFieldOfTypes(props:any){
  let field:string = props.field || "";
  let initialValue:string = props.initialValue || "";
  let className:string = props.className || "dataField";

  let vstring = initialValue.split('').join(''); // copy the variable so that props.o doesn't win
  let [value, setValue] = useState(vstring);

  function updateServer(s:string){
    setValue(s);
    // console.info("Send mutation for " + props.caseData.case_number + " to update " + props.o + " with value " + s);
  }


  if(caseEditTypes[field] === "textarea"){
    return(
      <textarea className={ className } name={ field } onChange={ (e) => updateServer(e.target.value) }>{value}</textarea>
    )
  }
  else if(caseEditTypes[field] === "date"){
    return(
      <input className={ className } type="date" value={ value } name={ field } onChange={ (e) => updateServer(e.target.value) } />
    )
  }
  else if(caseEditTypes[field] === "ngb"){
    return(
      <select className={ className } value={ value } name={ field } onChange={ (e) => updateServer(e.target.value) }>
      <option>Unknown</option>
      <option>U.S. Biathlon Association</option>
      <option>U.S. Bowling Congress</option>
      <option>U.S. Equestrian Federation</option>
      <option>U.S. Figure Skating</option>
      <option>U.S. Paralympic Snowboarding</option>
      <option>U.S. Paralympics Alpine Skiing</option>
      <option>U.S. Paralympics Cycling</option>
      <option>U.S. Paralympics Nordic Skiing</option>
      <option>U.S. Paralympics Swimming</option>
      <option>U.S. Paralympics Track &amp; Field</option>
      <option>U.S. Soccer</option>
      <option>U.S. Squash</option>
      <option>U.S. Tennis Association</option>
      <option>United States Association of Blind Athletes</option>
      <option>US Sailing</option>
      <option>US Ski &amp; Snowboard</option>
      <option>US Speedskating</option>
      <option>USA Archery</option>
      <option>USA Artistic Swimming</option>
      <option>USA Badminton</option>
      <option>USA Baseball</option>
      <option>USA Basketball</option>
      <option>USA Bobsled/Skeleton</option>
      <option>USA Boccia</option>
      <option>USA Boxing</option>
      <option>USA Climbing</option>
      <option>USA Curling</option>
      <option>USA Cycling</option>
      <option>USA Diving</option>
      <option>USA Fencing</option>
      <option>USA Field Hockey</option>
      <option>USA Golf</option>
      <option>USA Gymnastics</option>
      <option>USA Hockey</option>
      <option>USA Judo</option>
      <option>USA Luge</option>
      <option>USA National Karate-do Federation</option>
      <option>USA Pentathlon</option>
      <option>USA Racquetball</option>
      <option>USA Roller Sports</option>
      <option>USA Rugby</option>
      <option>USA Shooting</option>
      <option>USA Skateboarding</option>
      <option>USA Softball</option>
      <option>USA Surfing</option>
      <option>USA Swimming</option>
      <option>USA Table Tennis</option>
      <option>USA Taekwondo</option>
      <option>USA Team Handball</option>
      <option>USA Track &amp; Field</option>
      <option>USA Triathlon</option>
      <option>USA Volleyball</option>
      <option>USA Water Polo</option>
      <option>USA Water Ski &amp; Wake Sports</option>
      <option>USA Weightlifting</option>
      <option>USA Wrestling</option>
      <option>USOPC</option>
      <option>US Rowing</option>
      <option>Other</option>
      </select>
    )
  }
  else if(caseEditTypes[field] === "select_role"){
    return(
      <select className={ className } value={value} name={ field } onChange={ (e) => updateServer(e.target.value) }>
      <option>Athlete</option>
      <option>Coach</option>
      <option>Facility/Gym/Club Owner</option>
      <option>Other</option>
      <option value="">Unknown</option>
      </select>
    )
  }
  else if(caseEditTypes[field]=== "select_party_type"){
    return(
      <select className={ className } value={value} name={ field } onChange={ (e) => updateServer(e.target.value) }>
      <option>Unknown</option>
      <option>Claimant</option>
      <option>Respondent</option>
      <option>Third-Party Reporter</option>
      <option>Witness</option>
      <option>Advisor for Claimant</option>
      <option>Advisor for Respondent</option>
      <option>Other</option>
      </select>
    )
  }
  else if(caseEditTypes[field] === "select_casestage"){
    return(
      <select className={ className } value={value} name={ field } onChange={ (e) => updateServer(e.target.value) }>
      <option>Intake</option>
      <option>Preliminary Inquiry</option>
      <option>Investigation</option>
      <option>Awaiting Investigator Assignment</option>
      <option>Investigation Under Review</option>
      <option>Decision Issued - Pending Appeal</option>
      <option>In Arbitration</option>
      <option>Under Supervisor Review</option>
      <option>Case Closed</option>
      </select>
    )
  }
  else if(caseEditTypes[field] === "select_respondentstatus"){
    return(
      <select className={ className } value={value} name={ field } onChange={ (e) => updateServer(e.target.value) }>
      <option value="">None</option>
      <option>Temporary Suspension</option>
      </select>
    )
  }
  else if(caseEditTypes[field] === "select_currentdisposition"){
    return(
      <select className={ className } value={value} name={ field } onChange={ (e) => updateServer(e.target.value) }>
        <option value="">None</option>
        <option>Admin Hold</option>
        <option>Formal Admonishment</option>
        <option>Formal Warning</option>
        <option>Ineligible</option>
        <option>Lifted Sanction(s)</option>
        <option>Lifted Temporary Measure(s)</option>
        <option>Limited Participation</option>
        <option>No Violation (finding)</option>
        <option>Permanent Ineligibility</option>
        <option>Probation</option>
        <option>Suspended from all contact with minors</option>
        <option>Suspension</option>
        <option>Temporary Restriction(s)</option>
        <option>Temporary Suspension</option>
      </select>
    )
  }
  else if(caseEditTypes[field] === "select_publishstatus"){
    return(
      <select className={ className } value={value} name={ field } onChange={ (e) => updateServer(e.target.value) }>
        <option value="">None</option>
        <option>Published</option>
      </select>
    )
  }
  else if(caseEditTypes[field] === "select_contactmethod"){
    return(
      <select className={ className } value={value} name={ field } onChange={ (e) => updateServer(e.target.value) }>
        <option value="">Unknown</option>
        <option>Phone</option>
        <option>Online</option>
      </select>
    )
  }
  else if(caseEditTypes[field] === "select_jurisdictional_determination_type"){
    return(
      <select className={ className } value={value} name={ field } onChange={ (e) => updateServer(e.target.value) }>
        <option value="">Not yet established</option>
        <option>Exercised</option>
        <option>Split</option>
        <option>Declined</option>
        <option>No Personal</option>
        <option>No Subject Matter</option>
      </select>
    )
  }
  else if(caseEditTypes[field] === "select_severity" || caseEditTypes[field] === "select_complexity"){
    return(
      <select className={ className } value={value} name={ field } onChange={ (e) => updateServer(e.target.value) }>
        <option value="">Not yet established</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
      </select>
    )
  }
  else if(caseEditTypes[field] === "select_user_intake"){
    return(
      <select className={ className } value={value} name={ field } onChange={ (e) => updateServer(e.target.value) }>
        <option value="">None</option>
        <option>John Thamos</option>
        <option>Sally Intake</option>
        <option>Jerry Intaker</option>
        <option>Muriel Intáke</option>
      </select>
    )
  }
  else if(caseEditTypes[field] === "select_type_of_eligibility_action"){
    return(
      <select className={ className } value={value} name={ field } onChange={ (e) => updateServer(e.target.value) }>
        <option value="">Not yet established</option>
        <option>Admin Hold</option>
        <option>Formal Admonishment</option>
        <option>Temporary Restriction(s)</option>
        <option>Temporary Suspension</option>
        <option>Adopted Temporary Restriction(s)</option>
        <option>Adopt/Expand Temporary Restriction(s)</option>
        <option>Adopted Temporary Suspension</option>
        <option>Adopt/Expand Temporary Suspension</option>
        <option>Modified Temporary Restriction(s)</option>
        <option>Modified Temporary Suspension</option>
        <option>Sanction(s)</option>
        <option>Modified Sanction(s)</option>
        <option>No Violation (finding)</option>
      </select>
    )
  }
  else{
    return(
      <input className={ className } type="text" value={value} name={ field } onChange={ (e) => updateServer(e.target.value) } />
    )
  }
}

// "select_user_intake",
// "select_user",
// "select_user_investigation",
// ,


export default MakeDataFieldOfTypes;
