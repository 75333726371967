import {getUsername, getUID } from "./utilities"

function Dot(props:any){
  return(
    <div className="presenceDot" style={{ backgroundColor: props.bgcolor }} title={ props.fullname }>{props.initial}</div>
  )
}

function stringToHslColor(str:string, s:number, l:number) { // so random it's actually clever
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return 'hsl('+h+', '+s+'%, '+l+'%)';
}

function UserPresence(){
  // let possibleColors = ["#994424", "#1B76FF", "#1B76FF"];
  let uid = getUID();
  console.info(uid);
  let me = getUsername(uid);
  let u = { bg: stringToHslColor(me, 60, 70), initial: me.charAt(0), fullname: me }
  let users = [u];

  return(
    <div className="presence">
      { users.map( (u, i:number) => <Dot bgcolor={u.bg} initial={u.initial} fullname={u.fullname} />)}
    </div>
  )
}

export default UserPresence;
