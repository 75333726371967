import React, { useState } from 'react';
import FileManagerComponent from './filemanager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {} from './utilities'

interface newCaseDataStructure { // TODO: figure out why the export/import of the caseDataStructure from case.tsx is failing
  respondent_name: object,
  respondent_fullname: object,
  respondent_dob: object,
  respondent_ngb: object,
  respondent_role: object,
  intake_method: object,
  related_cases: object,
  incident_summary: object,
  incident_date: object,
  incident_location: object,
  reported_to_lawenforcement: object,
  trp_language: object
}

let defaultCase:newCaseDataStructure = {
  respondent_name: {
    default: "Respondent Name",
    input: "",
    required_type: "name"
  },
  respondent_fullname: {
    default: "",
    input: "",
    required_type: "name"
  },
  respondent_dob: {
    default: "",
    input: "",
    required_type: "date"
  },
  respondent_ngb: {
    default: "",
    input: "",
    required_type: "ngb"
  },
  respondent_role: {
    default: "",
    input: "",
    required_type: "role"
  },
  intake_method: {
    default: "",
    input: "",
    required_type: "method"
  },
  related_cases: {
    default: "",
    input: "",
    required_type: "cases_array"
  },
  incident_summary: {
    default: "",
    input: "",
    required_type: "string"
  },
  incident_date: {
    default: "",
    input: "",
    required_type: "date"
  },
  incident_location: {
    default: "",
    input: "",
    required_type: "location"
  },
  reported_to_lawenforcement: {
    default: "",
    input: "",
    required_type: "boolean"
  },
  trp_language: {
    default: "",
    input: "",
    required_type: "trp"
  }
}


interface FileList {
  name: string,
  mime_type: string,
  date_uploaded: string,
  uploaded_by: string,
  case_stage: string,
  tags: string
}

let caseFileList:FileList[] = [
]


function CreateCaseHeader(props: any) {
  return (
    <section>
      <div className="text">
        <div className="section_title">New Incident Report</div>
      </div>
    </section>
  );
}


interface CaseInput {
  respondent_name: string,
  respondent_fullname: string,
  respondent_dob: string,
  respondent_ngb_affiliation: string,
  respondent_role: string,
  case_stage: string,
  intake_method: string,
  related_cases: string,
  incident_summary: string,
  incident_date: string,
  reported_to_lawenforcement: string,
  incident_location: string,
  tpr_language: string,
  minor_involved: string
}


function CreateCase(){

  function lookupName(s:string):string{
    if(s === "respondent_name"){ return "name" }
    else if(s === "respondent_nickname"){ return "nickname" }
    else if(s === "respondent_dob"){ return "dob" }
    else if(s === "respondent_ngb_affiliation"){ return "ngb_affiliation" }
    else return s;
  }

  function handleSubmit(e:any) {

    e.preventDefault();


    let formElements = e.target.elements as HTMLFormElement;
    let submitObject :any = {};
    submitObject = {
      respondent_name: "",
      respondent_fullname: "",
      respondent_dob: "",
      respondent_ngb_affiliation: "",
      respondent_role: "",
      case_stage: "",
      intake_method: "",
      related_cases: "",
      incident_summary: "",
      incident_date: "",
      reported_to_lawenforcement: "",
      incident_location: "",
      tpr_language: "",
      minor_involved: "",
      parties: []
    }

    let respondent:any = {
      name: "",
      nickname: "",
      dob: "",
      ngb_affiliation: "",
      role: "respondent"
    }

    for(let x=0; x<formElements.length;x++){
      let tf = formElements[x] as HTMLFormElement;
      if(tf.classList.contains("dataField")){
        if(["respondent_name", "respondent_nickname", "respondent_dob", "respondent_ngb_affiliation"].includes(tf.name)){
          let n = lookupName( tf.name );
          respondent[n] = tf.value;
        }
        else{
          submitObject[ tf.name ] = tf.value;
        }
      }
    }

    submitObject.parties.push(respondent);

    fetch('https://gregjohnson.info/acorn/testpost.php', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(submitObject)
    })
      .then(response => response.json())
      .then(data => console.log(data));


  }

  return (
    <form onSubmit={ handleSubmit }>
      <CreateCaseHeader />
      <div className="sectionWrapper">
        <section className="colLeft">
          <div className="inputWrapper leftWrapper">
            <input type="text" className="dataField respondentName" name="respondent_name" placeholder="Respondent Name" />
            <div className="caseDetails twoColumn">
              <div className="dataSet">
                <div className="dataHeader">Respondent Nickname:</div><input className="dataField" name="respondent_nickname" placeholder="Tap to enter…"/>
                <div className="dataHeader">Respondent Date of Birth:</div><input className="dataField" name="respondent_dob" type="date"/>
                <div className="dataHeader">Respondent NGB Affiliation:</div>
                <select className="dataField" name="respondent_ngb_affiliation">
                  <option>Unknown</option>
                  <option>U.S. Biathlon Association</option>
                  <option>U.S. Bowling Congress</option>
                  <option>U.S. Equestrian Federation</option>
                  <option>U.S. Figure Skating</option>
                  <option>U.S. Paralympic Snowboarding</option>
                  <option>U.S. Paralympics Alpine Skiing</option>
                  <option>U.S. Paralympics Cycling</option>
                  <option>U.S. Paralympics Nordic Skiing</option>
                  <option>U.S. Paralympics Swimming</option>
                  <option>U.S. Paralympics Track &amp; Field</option>
                  <option>U.S. Soccer</option>
                  <option>U.S. Squash</option>
                  <option>U.S. Tennis Association</option>
                  <option>United States Association of Blind Athletes</option>
                  <option>US Sailing</option>
                  <option>US Ski &amp; Snowboard</option>
                  <option>US Speedskating</option>
                  <option>USA Archery</option>
                  <option>USA Artistic Swimming</option>
                  <option>USA Badminton</option>
                  <option>USA Baseball</option>
                  <option>USA Basketball</option>
                  <option>USA Bobsled/Skeleton</option>
                  <option>USA Boccia</option>
                  <option>USA Boxing</option>
                  <option>USA Climbing</option>
                  <option>USA Curling</option>
                  <option>USA Cycling</option>
                  <option>USA Diving</option>
                  <option>USA Fencing</option>
                  <option>USA Field Hockey</option>
                  <option>USA Golf</option>
                  <option>USA Gymnastics</option>
                  <option>USA Hockey</option>
                  <option>USA Judo</option>
                  <option>USA Luge</option>
                  <option>USA National Karate-do Federation</option>
                  <option>USA Pentathlon</option>
                  <option>USA Racquetball</option>
                  <option>USA Roller Sports</option>
                  <option>USA Rugby</option>
                  <option>USA Shooting</option>
                  <option>USA Skateboarding</option>
                  <option>USA Softball</option>
                  <option>USA Surfing</option>
                  <option>USA Swimming</option>
                  <option>USA Table Tennis</option>
                  <option>USA Taekwondo</option>
                  <option>USA Team Handball</option>
                  <option>USA Track &amp; Field</option>
                  <option>USA Triathlon</option>
                  <option>USA Volleyball</option>
                  <option>USA Water Polo</option>
                  <option>USA Water Ski &amp; Wake Sports</option>
                  <option>USA Weightlifting</option>
                  <option>USA Wrestling</option>
                  <option>USOPC</option>
                  <option>US Rowing</option>
                  <option>Other</option>
                </select>
                <div className="dataHeader">Respondent Role:</div>
                <select className="dataField" name="respondent_role">
                  <option>Unknown</option>
                  <option>Athlete</option>
                  <option>Coach</option>
                  <option>Facily/Club/Gym Owner</option>
                </select>
              </div>
              <div className="dataSet">
                <div className="dataHeader">Case Stage:</div>
                <select className="dataField" name="case_stage">
                  <option selected value="intake">Intake</option>
                  <option value="preliminary_inquiry">Preliminary Inquiry</option>
                  <option value="investigation">Investigation</option>
                  <option value="disposition">Disposition</option>
                </select>
                <div className="dataHeader">Intake Method:</div>
                <select className="dataField" name="intake_method">
                  <option selected value="phone">Phone</option>
                  <option value="email">Email</option>
                  <option value="in_person">In Person</option>
                </select>
                <div className="dataHeader">Related Cases:</div><input className="dataField" name="related_cases" placeholder="Search cases…"/>
              </div>
            </div>
            <div className="caseDetails space_above">
              <div className="dataHeader">Incident Summary:</div>
              <textarea className="dataField" name="incident_summary" placeholder="Tap to enter…"></textarea>
            </div>
            <div className="caseDetails twoColumn space_above">
              <div className="dataSet">
                <div className="dataHeader">Incident Date:</div><input className="dataField" name="incident_date" type="date" />
                <div className="dataHeader">Incident Location:</div><input className="dataField" name="incident_location" type="location" placeholder="City, State?" />
                <div className="dataHeader">Incident Environment:</div><select className="dataField" defaultValue="other" name="incident_environment">
                  <option value="gym">Gym</option>
                  <option value="locker room">Locker Room</option>
                  <option value="facility">Facility</option>
                  <option value="other">Other</option>
                </select>
                <div className="dataHeader">Was a minor involved?</div><select className="dataField" name="minor_involved">
                  <option selected>Unknown</option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
                <div className="dataHeader">Reported to Law Enforcement:</div><select className="dataField" name="reported_to_lawenforcement">
                  <option selected>Unknown</option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </div>
              <div className="dataSet">
                <div className="dataHeader">Reporter Relationship:</div>
                <div className="inputGroup narrow">
                  <select defaultValue="other" name="relationship" className="dataField">
                    <option value=""></option>
                    <option value="claimant">The victim</option>
                    <option value="witness">A witness</option>
                    <option value="advisor">An advisor</option>
                    <option value="other" selected>Other</option>
                  </select>
                </div>
                <div className="dataHeader">Third Party Reporter Language:</div>
                <select className="dataField" name="tpr_language">
                  <option>Choose…</option>
                </select>
              </div>
            </div>
            <div className="caseDetails buttonGroup space_above big_space_below">
              <button className="button">Submit New Intake</button>
              <button className="button">Merge To Case</button>
              <button className="button">Save as Draft</button>
              <input type="reset" className="button" value="Cancel New Intake" />
            </div>
          </div>
        </section>
        <section className="colRight">
          <div className="buttonWrapper">
            <div className="button case_print">Print/Export</div>
          </div>
        </section>
      </div>
      <FileManagerComponent fileList={ caseFileList } type="cases" />
    </form>
  )
}


export default CreateCase;
