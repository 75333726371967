function HandleLogin(){
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let uid = 0;
  if(params.uid){
    uid = parseInt(params.uid, 10);
  }
  sessionStorage.setItem('uid', JSON.stringify(uid));
  document.location.href = "/dashboard";
  return (
    <>
    </>
  )
}


export { HandleLogin }
