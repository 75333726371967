





function SearchResults(props: any) {
  return (
    <section>
      <div className="text">
        <div className="section_title">Search Results</div>
        <div className="section_caption">Found 3,202 records</div>
      </div>
    </section>
  );
}


export default SearchResults;
