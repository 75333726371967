import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import fauxCaseNotes from './casenotes'
import {getUsername, getUID } from "./utilities"
import { useState, useEffect } from "react";
import UserPresence from "./presence";

function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}


interface NoteComment{
  id: number,
  comment_author_uid: number,
  comment: string,
  comment_edit_date: string,
  comment_parent_id: number
}

interface CaseNote {
  id: number,
  note_author_uid: number,
  note: string,
  note_case_stage: string,
  date_published: string,
  date_last_edit: string,
  comments: NoteComment[]
}



function CaseNumber():string{
  let p = document.location.pathname;
  let case_number = p.replace("/notes/", "");
  return case_number;
}

function caseLink(){
  return "/case/" + CaseNumber();
}

function NotesCap() {
  return (
    <section className="no_bottom_border notes_section">
      <div className="caseHeader">
        <div className="back"><a href={ caseLink() }><FontAwesomeIcon icon={faArrowLeft} color="#283b5a" /></a></div><div className="caseNumber section_title">Notes for Case {CaseNumber()}</div>
        <div className="presenceDotWrapper">
          <UserPresence />
        </div>
      </div>
    </section>
  );
}

function CaseHeader(){

  return (
    <div className="sectionWrapper notes_section">
      <section className="colLeft notes_section">
        <div className="textWrapper">
          <div className="section_title">Billy Fakename, Athlete at USA Swimming</div>
          <div className="section_caption">Last updated at 9:06am, Thursday June 26, 2021</div>
        </div>
      </section>
      <section className="colRight">
        <div className="buttonWrapper">
          <div className="button case_share">Share Case</div>
          <div className="button case_print">Print/Export</div>
        </div>
      </section>
    </div>
  );
}

function AddNote(props:any){
  let [note, setNote] = useState("");
  let [isEditing, setIsEditing] = useState(false);
  let [saved, updateSaved] = useState("");
  let [lastSaved, updateLastSaved] = useState("");
  const debouncedValue = useDebounce<string>(lastSaved, 2500)

  useEffect(() => {
    // Do fetch here...
    // Triggers when "debouncedValue" changes
  }, [debouncedValue])

  function setEditandUpdate(etv:string){
    updateLastSaved("Last saved: Just now");
    setNote(etv);
    setIsEditing(true);
  }

  if(props.stage === props.currentStage && !isEditing){
    return(
      <section className="casenoteWrapper">
        <div className="casenoteDetails textWrapper fresh">
          <div className="caseNoteDatePublished">Draft</div>
          <div className="caseNoteAuthor">{ getUsername(getUID()) }</div>
          <div className="caseLastEdited">{ debouncedValue }</div>
        </div>
        <textarea className="addNote caseNote" name="addNote" value={ note } placeholder="Add note" onChange={ (e) => setEditandUpdate(e.target.value) }></textarea>
        <div className="caseNoteComments"></div>
      </section>
    )
  }
  if(props.stage === props.currentStage && isEditing){
    return(
      <section className="casenoteWrapper">
        <div className="casenoteDetails textWrapper">
          <div className="caseNoteDatePublished">Draft</div>
          <div className="caseNoteAuthor">{ getUsername(getUID()) }</div>
          <div className="caseLastEdited">{ debouncedValue }</div>
        </div>
        <textarea className="addNote caseNote" name="addNote" value={ note } placeholder="Add note" onChange={ (e) => setNote(e.target.value) }></textarea>
        <div className="caseNoteComments">
          <CaseComments parent_id={ 8383737 } comments={ [] } />
        </div>
      </section>
    )
  }
  else{
    return(
      <>
      </>
    )
  }
}

function NotesManager(props:any){
  let caseStages:string[] = [];
  for(let x=0;x<fauxCaseNotes.length;x++){
    if(!caseStages.includes(fauxCaseNotes[x].note_case_stage)){
      caseStages.push(fauxCaseNotes[x].note_case_stage);
    }
  }
  if(!caseStages.includes(props.stage)){
    return(
      <>
        <div className="caseStageHeader textWrapper empty">{ props.stage }</div>
        <AddNote stage={ props.stage } currentStage={ props.currentStage } />
      </>
    )
  }
  else{
    return(
      <>
        <div className="caseStageHeader textWrapper">{ props.stage }</div>
        { fauxCaseNotes.filter( (e:any ) => e.note_case_stage === props.stage ).map( (notedetail:any, i:number) => <CaseNote key={i} details={ notedetail } />) }
        <AddNote stage={ props.stage } currentStage={ props.currentStage } />
      </>
    )
  }
}

function dateDisplay(date:string){
  return new Date(date).toLocaleDateString('en-US');
}

function CaseNote(props:any){
  let [isEditing, setIsEditing] = useState(false);
  let [note, setNote] = useState(props.details.note);

  function handlePaste(e:any){
    e.preventDefault();
    var str = e.clipboardData.getData('Text');
    let temp = document.createElement("div");
    temp.innerText = str.replace("&nbsp;"," ");
    window.document.execCommand('insertText', false, temp.innerText);
  }

  // TODO: implment draft.js or just 

  if(isEditing){
    return(
      <section className="casenoteWrapper">
        <div className="casenoteDetails textWrapper">
          <div className="caseNoteDatePublished">{ dateDisplay(props.details.date_published) }</div>
          <div className="caseNoteAuthor">{ getUsername(props.details.note_author_uid) }</div>
          <CaseNoteEditDisplay publishDate={ props.details.date_published } editDate="In progress" />
        </div>
        <textarea className="addNote caseNote" name="addNote" value={ note } placeholder="Add note" onBlur={ (e) => setIsEditing(false )} onChange={ (e) => setNote(e.target.value) }>{ note }</textarea>
        <div className="caseNoteComments">
          <CaseComments parent_id={ props.details.id } comments={ props.details.comments } />
        </div>
      </section>
    )
  }
  else{
    return(
      <section className="casenoteWrapper">
        <div className="casenoteDetails textWrapper">
          <div className="caseNoteDatePublished">{ dateDisplay(props.details.date_published) }</div>
          <div className="caseNoteAuthor">{ getUsername(props.details.note_author_uid) }</div>
          <CaseNoteEditDisplay publishDate={ note.date_published } editDate={ props.details.date_last_edit } />
        </div>
        <div className="caseNote" onDoubleClick={ (e) => setIsEditing(true) }>{ note }</div>
        <div className="caseNoteComments">
          <CaseComments parent_id={ props.details.id } comments={ props.details.comments } />
        </div>
      </section>
    )
  }
}

function CaseComments(props:any){
  let [comments, updateComments] = useState(props.comments)
  let [reply, setReply] = useState("");
  let [isEditing, setIsEditing] = useState(false);

  function generateID(id:number){
    return "comment_" + id;
  }

  function addReply() {
    if(!!reply){
      let r = {
        id: Math.random() * 39292023,
        comment_author_uid: getUID(),
        comment: reply,
        comment_edit_date: new Date().toISOString(),
        comment_parent_id: 0
      }
      let newcomments = [...comments];
      newcomments.push(r);
      updateComments(newcomments);
      console.info(comments);
      setIsEditing(false);
      setReply("");
      let replyInput = document.getElementsByName("comment_reply")[0] as HTMLFormElement;
      replyInput.value = ""; // this seems wholly unnecessary…
    }
  }

  if(comments.length === 0 && !isEditing){
    return(
      <div className="commentBar" onClick={ (e) => setIsEditing(true) }></div>
    )
  }
  else if(comments.length === 0 && isEditing){
    return(
      <>
      <div className="commentBar hasComment"></div>
      <div className="commentWrapper">
      <textarea placeholder="Add comment…" className="comment_reply" name="comment_reply" onFocus={ (e) => setIsEditing(true) } onChange={ (e) => setReply(e.target.value) } value={reply}></textarea>
      <div className="buttonGroup"><button className={ isEditing ? "active" : "inactive"} onClick={ addReply } >Comment</button></div>
    </div>
      </>
    )
  }
  else{
    return(
      <>
        <div className="commentBar hasComment"></div>
        <div className="commentWrapper">
        { comments.map( (cc:any, i:number) =>
          <>
          <div className="commentAuthor">{ getUsername(cc.comment_author_uid) }</div>
          <div className="commentDate">{ cc.comment_edit_date }</div>
          <div className="comment">{ cc.comment }</div>
          </>
        )}

        <textarea placeholder="Add comment…" className="comment_reply" name="comment_reply" id={ generateID(props.parent_id) } onFocus={ (e) => setIsEditing(true) } onBlur={ (e) => setIsEditing(false) } onChange={ (e) => setReply(e.target.value) } value={reply}></textarea>
        <div className="buttonGroup"><button className={ isEditing ? "active" : "inactive"} onClick={ addReply } >Comment</button></div>
      </div>
      </>
    )
  }
}

function CaseNoteEditDisplay(props:any){
  if(props.publishDate !== props.editDate && props.editDate === "In progress"){
    return (
      <div className="caseLastEdited">Editing: { props.editDate }</div>
    )
  }
  else if(props.publishDate !== props.editDate){
    return (
      <div className="caseLastEdited">Edited: { props.editDate }</div>
    )
  }
  else{
    return (
      <>
      </>
    )
  }
}


function NotesApp(props:any) {
  return(
    <span>
      <NotesCap />
      <CaseHeader />
      <NotesManager stage="Intake" currentStage="Investigation" />
      <NotesManager stage="Preliminary Inquiry" currentStage="Investigation" />
      <NotesManager stage="Investigation" currentStage="Investigation" />
    </span>
  );
}

export default NotesApp;
