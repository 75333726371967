import { useState } from "react";

function WidgetTeamCaseload(props:any){
  let teamAssignments = [
     { "name": "Sandra DeFaké", "role": "P.I. Investigator", "caseload": 83, "delta": "1", "class_ratio": "5.95%" },
     { "name": "Morris Whatnot", "role": "Investigator", "caseload": 77, "delta": "-3", "class_ratio": "5.52%" },
     { "name": "Barry Madeup", "role": "Investigator", "caseload": 75, "delta": "5", "class_ratio": "5.37%" },
     { "name": "Carrie Anotname", "role": "Intake", "caseload": 58, "delta": "-7", "class_ratio": "4.15%" },
     { "name": "Anon Ymous", "role": "Intake", "caseload": 55, "delta": "-8", "class_ratio": "3.94%" },
     { "name": "Samuel Needothername", "role": "Case Management Support Manager", "caseload": 53, "delta": "3", "class_ratio": "3.80%" },
     { "name": "Rebecca Sinnombre", "role": "P.I. Investigator", "caseload": 48, "delta": "1", "class_ratio": "3.44%" },
     { "name": "Amelia Sansnom", "role": "Resolution Specialist", "caseload": 43, "delta": "-1", "class_ratio": "3.08%" },
     { "name": "Robert Keinname", "role": "Case Management Support Manager", "caseload": 33, "delta": "--", "class_ratio": "2.36%" },
     { "name": "Harriett Ekkertnafn", "role": "Investigator", "caseload": 32, "delta": "1", "class_ratio": "2.29%" },
     { "name": "Lars Ingetnamn", "role": "P.I. Investigator", "caseload": 32, "delta": "--", "class_ratio": "2.29%" },
     { "name": "Noah Chorisonoma", "role": "Resolution Specialist", "caseload": 32, "delta": "--", "class_ratio": "2.29%" },
     { "name": "Enelis Bevardo", "role": "Intake", "caseload": 28, "delta": "-1", "class_ratio": "2.01%" },
     { "name": "Wario Senzanome", "role": "Intake", "caseload": 26, "delta": "--", "class_ratio": "1.86%" },
     { "name": "Agustí Sensenom", "role": "Intake", "caseload": 23, "delta": "23", "class_ratio": "1.65%" }
  ]

  function Assignment(props:any){
    let delta = props.delta > 0 ? "+" + props.delta : props.delta;
    return(
      <tr>
        <td>{ props.name }</td>
        <td>{ props.role }</td>
        <td>{ props.caseload }</td>
        <td className={ delta > 0 ? "green" : delta < 0 ? "red" : "" }>{ delta }</td>
        <td>{ props.class_ratio }</td>
      </tr>
    )
  }

  return(
    <section className="colLeft">
      <div className="leftWrapper">
        <div className="wrapperHeader">Team Member Utilization</div>
        <table className="widget utils dashboard_table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Classification/Role</th>
              <th>Caseload</th>
              <th>&Delta; W/W</th>
              <th>Class Ratio</th>
            </tr>
          </thead>
          <tbody>
            { teamAssignments.map( (ta, i) => <Assignment id={ i } name={ ta.name } role={ ta.role } caseload={ ta.caseload } delta={ ta.delta } class_ratio={ ta.class_ratio } /> ) }
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default WidgetTeamCaseload;
