import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faGripHorizontal, faClipboard, faChartBar, faUsersCog } from '@fortawesome/free-solid-svg-icons'


function Navigation(props: any): any {
  let navList = [
    { label: "Dashboard", icon: faGripHorizontal, url: "/dashboard", isactive: false },
    { label: "Cases", icon: faClipboard, url: "/cases", isactive: false },
    { label: "Reporting", icon: faChartBar, url: "/reporting", isactive: false },
    { label: "Resources", icon: faBook, url: "/resources", isactive: false },

  ];

  if(props.role === "Administrator"){
    navList.push({ label: "Administration", icon: faUsersCog, url: "/administration", isactive: false },)
  }

  for(let x=0;x<navList.length;x++){
    if(props.active === navList[x].label){
      navList[x].isactive = true;
    }
  }

  return (
    <ul>
      {navList.map((object, i) => <MakeNavItem url={object.url} label={object.label} isactive={object.isactive} icon={object.icon} key={i} />)}
    </ul>
  );
}

function MakeNavItem(props:any): any{
  if(props.isactive){
    return (
      <li className="active">
        <span>
          <FontAwesomeIcon icon={props.icon} />
          {props.label}
        </span>
      </li>
    );
  }
  else{
    return (
      <li>
        <a href={props.url} className={props.active}>
          <FontAwesomeIcon icon={props.icon} />
          {props.label}
        </a>
      </li>
    );
  }
}

export default Navigation;
