import FileManagerComponent from './filemanager';


interface FileList {
  name: string,
  mime_type: string,
  date_uploaded: string,
  uploaded_by: string,
  case_stage: string,
  tags: string,
  institution: string
}

let contentLibraryFileList:FileList[] = [
  { name: "Long form descriptive filename.pdf", mime_type: "application/pdf", date_uploaded: "May 3, 2021", uploaded_by: "Mary Noname", case_stage: "", tags: "", institution: "" },
  { name: "Some other type of media object.png", mime_type: "image/png", date_uploaded: "May 7, 2021", uploaded_by: "Mary Noname", case_stage: "", tags: "", institution: "" },
  { name: "Additional media.jpg", mime_type: "image/jpeg", date_uploaded: "May 9, 2021", uploaded_by: "Barry Madeup", case_stage: "", tags: "", institution: "" },
  { name: "Welcome message.mp3", mime_type: "audio/mpeg", date_uploaded: "May 14, 2021", uploaded_by: "Mary Noname", case_stage: "", tags: "", institution: "" },
  { name: "Generic media object.mov", mime_type: "video/mpeg", date_uploaded: "May 14, 2021", uploaded_by: "Mary Noname", case_stage: "", tags: "", institution: "" },
  { name: "Updated document name.docx", mime_type: "application/msword", date_uploaded: "May 14, 2021", uploaded_by: "Mark Ham-El", case_stage: "", tags: "", institution: "" }
]

let policyLibraryFileList:FileList[] = [
  { name: "Long form descriptive filename.pdf", mime_type: "application/pdf", date_uploaded: "May 3, 2021", uploaded_by: "Mary Noname", case_stage: "", tags: "", institution: "USCSS" },
  { name: "Long form descriptive filename.pdf", mime_type: "application/pdf", date_uploaded: "May 7, 2021", uploaded_by: "Mary Noname", case_stage: "", tags: "", institution: "USCSS" },
  { name: "Long form descriptive filename.pdf", mime_type: "application/pdf", date_uploaded: "May 9, 2021", uploaded_by: "Barry Madeup", case_stage: "", tags: "", institution: "USA Soccer" },
  { name: "Long form descriptive filename.pdf", mime_type: "application/pdf", date_uploaded: "May 14, 2021", uploaded_by: "Mary Noname", case_stage: "", tags: "", institution: "NCAA" },
  { name: "Long form descriptive filename.pdf", mime_type: "application/pdf", date_uploaded: "May 14, 2021", uploaded_by: "Mary Noname", case_stage: "", tags: "", institution: "State of Wyoming" },
  { name: "Long form descriptive filename.pdf", mime_type: "application/pdf", date_uploaded: "May 14, 2021", uploaded_by: "Mark Ham-El", case_stage: "", tags: "", institution: "USCSS" }
]








function ResourcesHeader(props:any){
  return(
    <section>
      <div className="text">
        <div className="section_title">Resources</div>
        <div className="section_caption">Last updated yesterday</div>
      </div>
    </section>
  )
}


function Resources(props: any) {
  return (
    <span>
      <ResourcesHeader />
      <FileManagerComponent fileList={ contentLibraryFileList } title="Content Library" type="resources" />
      <FileManagerComponent fileList={ policyLibraryFileList } title="Policy Library" type="policies" />
    </span>
  );
}


export default Resources;
