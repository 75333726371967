import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faFileExport, faUsers, faWindowClose } from '@fortawesome/free-solid-svg-icons'



interface UserData {
  name: string,
  role: string,
  creationdate: string,
  dateofrolechange: string,
  status: string
}

interface RoleData {
  role: string,
  permissions: string,
  counts: number,
  dateoflastchange: string
}




function Users(props:any) {
  const userslist:UserData[] = [
   { "name": "Carrie Anotname", "role": "P.I. Investigator", "creationdate": "08/04/19", "dateofrolechange": "04/04/21", "status": "Active today" },
   { "name": "Enelis Bevardo", "role": "Investigator", "creationdate": "12/14/19", "dateofrolechange": "01/14/20", "status": "Active yesterday" },
   { "name": "Noah Chorisonoma", "role": "Investigator", "creationdate": "01/09/21", "dateofrolechange": "01/09/21", "status": "Active today" },
   { "name": "Sandra DeFaké", "role": "Intake", "creationdate": "03/05/19", "dateofrolechange": "09/20/19", "status": "Active today" },
   { "name": "Harriett Ekkertnafn", "role": "Intake", "creationdate": "04/10/20", "dateofrolechange": "01/24/21", "status": "Inactive" },
   { "name": "Lars Ingetnamn", "role": "Case Management Support Manager", "creationdate": "01/14/19", "dateofrolechange": "01/14/19", "status": "Active today" },
   { "name": "Robert Keinname", "role": "P.I. Investigator", "creationdate": "04/21/19", "dateofrolechange": "07/03/19", "status": "Active yesterday" },
   { "name": "Barry Madeup", "role": "Resolution Specialist", "creationdate": "11/09/19", "dateofrolechange": "04/13/21", "status": "Active one week ago" },
   { "name": "Samuel Needothername", "role": "Case Management Support Manager", "creationdate": "10/18/19", "dateofrolechange": "03/27/20", "status": "Active today" },
   { "name": "Amelia Sansnom", "role": "Investigator", "creationdate": "04/27/19", "dateofrolechange": "03/28/21", "status": "Active yesterday" },
   { "name": "Agustí Sensenom", "role": "P.I. Investigator", "creationdate": "01/14/19", "dateofrolechange": "04/12/19", "status": "Active today" },
   { "name": "Wario Senzanome", "role": "Resolution Specialist", "creationdate": "07/09/19", "dateofrolechange": "04/29/21", "status": "Active today" },
   { "name": "Rebecca Sinnombre", "role": "Intake", "creationdate": "04/26/20", "dateofrolechange": "04/26/20", "status": "Active yesterday" },
   { "name": "Morris Whatnot", "role": "Intake", "creationdate": "09/07/20", "dateofrolechange": "09/07/20", "status": "Suspended" },
   { "name": "Anon Ymous", "role": "Intake", "creationdate": "08/28/20", "dateofrolechange": "03/15/21", "status": "Active today" }
  ];

  function UserRow(props:any){
    return (
      <tr>
        <td><input type="checkbox" /></td>
        <td className="username">{ props.name }</td>
        <td className="userrole">{ props.role }</td>
        <td>{ props.creationdate }</td>
        <td>{ props.dateofrolechange }</td>
        <td>{ props.status }</td>
      </tr>
    );
  }


  return (
    <section className="no_bottom_border">
      <div>
        <div className="controls">
          <div className="control_state">
            Manage Users
            <div className="control_state_change">Tap to Change Filters</div>
          </div>
          <div className="control" id="suspendUsers">
            <div className="control_icon"><FontAwesomeIcon icon={faUsers} /></div>
            Suspend
          </div>
          <div className="control" id="showClosed">
            <div className="control_icon"><FontAwesomeIcon icon={faFileExport} /></div>
            Export
          </div>
          <div className="control active" id="configureView">
            <div className="control_icon"><FontAwesomeIcon icon={faClone} /></div>
            Batch Update
          </div>
          </div>
        </div>

        <div className="table_holder">
          <table className="dashboard_table">
            <thead>
              <tr className="tablehead">
                <th className="checkheader"><input type="checkbox" id="checkall" /></th>
                <th>User Name</th>
                <th>User Role</th>
                <th>Creation Date</th>
                <th>Date of Last Update</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {userslist.map((ud:UserData) => <UserRow name={ ud.name } role={ ud.role } creationdate={ ud.creationdate } dateofrolechange={ ud.dateofrolechange } status={ ud.status } />)}
            </tbody>
          </table>
        </div>
    </section>
  );
}



function Roles(props:any) {
  const roleslist:RoleData[] = [
   { "role": "Intake Coordinator", "permissions": "Create New Case, View Assigned Cases", "counts": 7, "dateoflastchange": "04/04/21" },
   { "role": "Intake Manager", "permissions": "Create New Case, View Assigned Cases", "counts": 5, "dateoflastchange": "01/14/20" },
   { "role": "P.I. Investigator", "permissions": "Create New Case, View/Edit/Reassign Assigned Cases", "counts": 6, "dateoflastchange": "01/09/21" },
   { "role": "P.I. Manager", "permissions": "Create New Case, View/Edit/Reassign Assigned Cases, Advance Case", "counts": 3, "dateoflastchange": "09/20/19" },
   { "role": "Investigator", "permissions": "Create New Case, View/Edit/Reassign Assigned Cases", "counts": 6, "dateoflastchange": "01/24/21" },
   { "role": "Investigator Manager", "permissions": "Create New Case, View/Edit/Reassign Assigned Cases, Advance Case", "counts": 8, "dateoflastchange": "01/14/19" },
   { "role": "Case Management Support", "permissions": "Create New Case, View/Edit Assigned Cases", "counts": 3, "dateoflastchange": "07/03/19" },
   { "role": "Case Management Support Manager", "permissions": "Create New Case, View/Edit Assigned Cases", "counts": 5, "dateoflastchange": "04/13/21" },
   { "role": "Resolution Specialist", "permissions": "Create New Case, View/Edit Assigned Cases", "counts": 7, "dateoflastchange": "03/27/20" },
   { "role": "Team Lead", "permissions": "Create New Case, View/Edit/Reassign Assigned Cases, Advance Case, Edit Users", "counts": 9, "dateoflastchange": "03/28/21" },
   { "role": "Director", "permissions": "Create New Case, View/Edit/Reassign Assigned Cases, Advance Case, Edit/Modify Users", "counts": 6, "dateoflastchange": "04/12/19" },
   { "role": "System Administrator", "permissions": "All", "counts": 4, "dateoflastchange": "04/29/21" }
  ]


  function RoleRow(props:any){
    return (
      <tr>
        <td><input type="checkbox" /></td>
        <td className="username">{ props.role }</td>
        <td className="userrole">{ props.permissions }</td>
        <td>{ props.counts }</td>
        <td>{ props.dateoflastchange }</td>
      </tr>
    );
  }


  return (
    <section className="no_bottom_border">
      <div>
        <div className="controls">
          <div className="control_state">
            Manage Roles
            <div className="control_state_change">Tap to Change Filters</div>
            </div>
            <div className="control" id="suspendUsers">
              <div className="control_icon"><FontAwesomeIcon icon={faWindowClose} /></div>
              Delete Role
            </div>

          </div>
        </div>

        <div className="table_holder">
          <table className="dashboard_table">
            <thead>
              <tr className="tablehead">
                <th className="checkheader"><input type="checkbox" id="checkall" /></th>
                <th>Role</th>
                <th>Permissions</th>
                <th>Counts</th>
                <th>Date of Last Change</th>
              </tr>
            </thead>
            <tbody>
              {roleslist.map((rd:RoleData) => <RoleRow role={ rd.role } permissions={ rd.permissions } dateoflastchange={ rd.dateoflastchange } counts={ rd.counts } />)}
            </tbody>
          </table>
        </div>
    </section>
  );
}







function Administration(props: any) {
  return (
    <span>
      <section>
        <div className="text">
          <div className="section_title">Administration</div>
          <div className="section_caption">Securely signed in as Mary Noname</div>
        </div>
      </section>
      <Users />
      <Roles />
    </span>
  );
}


export default Administration;
