import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { getUID, getUser, handleLogout } from "./utilities";
import UserPresence from './presence';

function toggleClick(){
  let dp = document.getElementById('dropdown_profile');
  if(dp != null){
    if(!dp.classList.contains("active")){
      listenToClearDropdown();
    }
    dp.classList.toggle('active');
  }
}

function listenToClearDropdown():void {
  window.addEventListener("click", function(event: MouseEvent){
    let et = event.target as HTMLElement;
    if(et.getAttribute("id") !== "dropdown_profile"){
      toggleClick();
    }
  });
}

function getSearchParams() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const entries = Object.fromEntries(urlSearchParams.entries());
  if(entries.q){
    return entries.q;
  }
}

function showSuggestions(){
  let si = document.getElementById("suggestedInputs");
  if(si != null){
    si.classList.toggle('active');
  }
}

function hideSuggestions(){
  let si = document.getElementById("suggestedInputs");
  if(si != null){
    si.classList.remove('active');
  }
}

function searchText(e:any){
  if(e.key && e.key === "Enter"){
    e.preventDefault();
    console.info("Go");
    let si = document.getElementById("search_input");
    if(si != null){
      if(si.innerText !== ""){
        let searchText = encodeURIComponent(si.innerText);
        window.location.href = "/search?q=" + searchText;
      }
    }
  }
}

function showNotifications(){
  document.location.href = "/notifications";
}


function Search() {
  let uid = getUID();
  let userDetails = getUser(uid);

  return (
    <div id="utilities">
      <div className="input-group">
        <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faSearch} /></span>
        <div className="form-control placeholder" id="search_input" data-placeholder="Search all cases…" contentEditable="true" onKeyDown={searchText} onFocus={showSuggestions} onBlur={hideSuggestions}>{getSearchParams()}</div>
        <div id="suggestedInputs">
          <div className="suggestedHeader">SUGGESTED FILTERS</div>
          <div className="suggestion"><span className="walkthrough">case:</span> case number</div>
          <div className="suggestion"><span className="walkthrough">respondent:</span> respondent’s first name</div>
          <div className="suggestion"><span className="walkthrough">incident:</span> date of incident (MM/DD/YYYY)</div>
          <div className="suggestion"><span className="walkthrough">ngb:</span> affiliated NGB</div>
          <div className="suggestion"><span className="walkthrough">status:</span> case status</div>
        </div>
      </div>

      <div className="utilities_navigation">
        <div className="help">Help</div>
        <div className="notifications" onClick={ showNotifications }>
          Notifications
          <div className="dot">1</div>
        </div>
        <div className="account dropdown" id="dropdown_profile" onClick={toggleClick}>My Account
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li><span className="dropdown-header">Logged in as { userDetails.name }</span></li>
            <li><span className="dropdown-item" onClick={ handleLogout }>Log Out</span></li>
          </ul>
        </div>
        <UserPresence />
      </div>
    </div>
  );
}

export default Search;
