import ScatterChart from './dashboard_scatter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import TaskList from "./taskwidget";
import WidgetTeamCases from "./widget_teamcases"
import WidgetNewIntakes from "./widget_newintakes"
import WidgetTeamCaseload from "./widget_teamcaseload"


let mycases = [
   { "case_number": "IR2021-9302", "last_update": "Yesterday", "last_updated_by": "Barry Madeup", "member_records_received": "4 days ago", "sla_remaining": "14 days", "flag": "" },
   { "case_number": "IR2021-7212", "last_update": "3 days ago", "last_updated_by": "Sandra DeFaké", "member_records_received": "", "sla_remaining": "22 days", "flag": "" },
   { "case_number": "IR2021-8743", "last_update": "5 days ago", "last_updated_by": "Carrie Anotname", "member_records_received": "", "sla_remaining": "9 days", "flag": "" },
   { "case_number": "IR2021-6699", "last_update": "Last week", "last_updated_by": "Enelis Bevardo", "member_records_received": "Two weeks ago", "sla_remaining": "2 days", "flag": "atrisk" },
   { "case_number": "IR2021-7112", "last_update": "Last week", "last_updated_by": "Carrie Anotname", "member_records_received": "Yesterday", "sla_remaining": "8 days", "flag": "" },
   { "case_number": "IR2021-7402", "last_update": "Two weeks ago", "last_updated_by": "Barry Madeup", "member_records_received": "", "sla_remaining": "4 days", "flag": "" }
]


function WidgetMyCases(props:any){
  let mycases = props.mycases;

  function openCase(caseNumber:string) {
    let url = "/case/".concat(caseNumber);
    window.location.href = url;
  }

  return (
    <div className="widgetWrapper">
      <div className="wrapperHeader">My Cases</div>
      <table className="dashboard_table widget hasAction">
        <thead>
          <tr className="tablehead">
            <th>Case #</th>
            <th>Last Updated</th>
            <th>Last Updated By</th>
            <th>Member Records</th>
            <th>SLA Remaining</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          { mycases.map((mc:any, i:number) => <tr key={ "case" + i} className={ mc.flag } onClick={(event: React.MouseEvent<HTMLElement>) => { openCase(mc.case_number) }}>
            <td>{ mc.case_number }</td>
            <td>{ mc.last_update }</td>
            <td>{ mc.last_updated_by }</td>
            <td>{ mc.member_records_received }</td>
            <td>{ mc.sla_remaining }</td>
            <td>{ mc.flag === "atrisk" ? ( <FontAwesomeIcon icon={faExclamationTriangle} /> ) : "" }</td>
          </tr>)}
        </tbody>
      </table>
    </div>
  )
}

function WidgetOpenCases(){
  return (
    <div className="sectionWrapperFull">
      <section className="colFull">
        <div className="wrapper">
          <div className="wrapperHeader">
            <div className="label">Open Cases Snapshot <span className="light">(793 Active Cases)</span></div>
          </div>
          <div className="widget opencases">
            <ScatterChart />
          </div>
        </div>
      </section>
    </div>
  )
}


function WidgetKeyMetrics(){
  let keyMetrics = [
    { label: "New Intakes Last 7 Days", delta: "+1", value: 27, color: "#76DE7A" },
    { label: "Unassigned Cases", delta: "+4", value: 42, color: "#76DE7A"  },
    { label: "Recently Closed Cases", delta: "+19", value: 53, color: "#76DE7A"  },
    { label: "Out of SLA Compliance", delta: "+2", value: 27, color: "#FFA4AC"  },
    { label: "Average Case Age", delta: "+0.3", value: "22.3 days", color: "#76DE7A" }
  ]

  function MakeMetric(props:any){
    return(
      <div className="keyMetric">
        <div className="value">{ props.value }</div>
        <div className="label">{ props.label}</div>
        <div className="delta" style={{ color: props.color }}>{ props.delta } over the last 30 days</div>
      </div>
    )
  }

  return (
    <div className="sectionWrapperFull">
      <section className="colFull">
        <div className="wrapper">
          <div className="wrapperHeader">
            <div className="label">Open Cases Key Metrics</div>
          </div>
          <div className="widget keyMetrics">
            { keyMetrics.map((metric, i) => <MakeMetric label={ metric.label } delta={ metric.delta } value={ metric.value } color={ metric.color } /> )}
          </div>
        </div>
      </section>
    </div>
  )
}




function WidgetNGBAffilation(){
  let ngbStatistics = [
 { "ngb": "USA Swimming", "cases": 184 },
 { "ngb": "USOPC", "cases": 140 },
 { "ngb": "USA Gymnastics", "cases": 93 },
 { "ngb": "USA Field Hockey", "cases": 77 },
 { "ngb": "USA Track & Field", "cases": 64 },
 { "ngb": "USA Volleyball", "cases": 58 },
 { "ngb": "USA Basketball", "cases": 32 },
 { "ngb": "USA Artistic Swimming", "cases": 28 },
 { "ngb": "US Ski & Snowboard", "cases": 22 },
 { "ngb": "U.S. Figure Skating", "cases": 17 },
 { "ngb": "US Sailing", "cases": 14 },
 { "ngb": "Unknown", "cases": 12 },
 { "ngb": "United States Association of Blind Athletes", "cases": 9 },
 { "ngb": "Unaffiliated", "cases": 9 },
 { "ngb": "U.S. Paralympics Alpine Skiing", "cases": 9 }
]

  function NGBStatsRow(props:any){
    return(
      <tr>
        <td>{ props.ngb }</td>
        <td>{ props.cases }</td>
      </tr>
    )
  }

  return(
    <section className="colRight">
      <div className="rightWrapper">
        <div className="wrapperHeader">NGB Affiliation Statistics</div>
        <table className="widget utils dashboard_table">
          <thead>
            <tr>
              <th>Affiliated NGB</th>
              <th>Cases</th>
            </tr>
          </thead>
          <tbody>
            { ngbStatistics.map( (n, i) => <NGBStatsRow id={ i } ngb={ n.ngb } cases={ n.cases } /> ) }
          </tbody>
        </table>
      </div>
    </section>
  )
}



function WidgetHeader(props:any){
  return (
    <section>
      <div className="text twoColumn">
        <div className="textWrapper">
          <div className="section_title">Good afternoon, { props.userDetails.nickname }!</div>
          <div className="section_caption">Last signed in yesterday.</div>
        </div>
      </div>
    </section>
  )
}


function DashboardWidgets(props:any){
  if(props.userDetails.role === "Administrator"){
    return (
      <>
        <WidgetOpenCases />
        <WidgetKeyMetrics />
        <div className="sectionWrapper">
          <WidgetTeamCaseload />
          <WidgetNGBAffilation />
        </div>
      </>
    );
  }
  else if(props.userDetails.role === "External Investigator"){
    return (
      <>
        <WidgetNGBAffilation />
      </>
    );
  }
  else{ // "Intake Coordinator"
    return (
      <>
        <div className="sectionWrapper widgetTwoColumns">
          <section className="colLeft">
            <WidgetMyCases mycases={ mycases } />
          </section>
          <section className="colRight">
            <TaskList uid={props.userDetails.uid} mycases={ mycases } />
          </section>
        </div>
        <div className="sectionWrapper">
          <WidgetTeamCases team="intake" />
        </div>
        <div className="sectionWrapper">
          <WidgetNewIntakes />
        </div>
        <div className="sectionWrapper">
          <WidgetTeamCaseload team="intake" role="member" />
        </div>
      </>
    );
  }
}


function Dashboard(props:any) {
  return (
    <>
      <WidgetHeader userDetails={ props.userDetails } />
      <DashboardWidgets userDetails={ props.userDetails } />
    </>
  )
}


export default Dashboard;
