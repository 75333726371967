
function Footer() {
  return (
    <footer>
      <div className="footerWrapper">
        <p>
          USCSS Case Management Application<br />
          Last updated: September 11, 2021
        </p>
        <div className="footerLinks">
          <a href="/help">Help Desk</a>
          <a href="/submit_ticket">Submit a Ticket</a>
        </div>
      </div>
    </footer>
  );
}


export default Footer;
