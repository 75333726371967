import { useState } from "react";

function WidgetNewIntakes(props:any){

  let newIRs = [
   { "case_number": "IR2021-9723", "time_received": "09:32am MT", "date_received": "6/30/2021", "respondent_name": "Skyler Berger", "claimant_name": "Garrett Jacobs", "last_updated": "", "sla_remaining": "28 days" },
   { "case_number": "IR2021-9724", "time_received": "11:45am MT", "date_received": "6/30/2021", "respondent_name": "Sanaa Byrd", "claimant_name": "Ryan Tyler", "last_updated": "", "sla_remaining": "28 days" },
   { "case_number": "IR2021-9725", "time_received": "8:52pm MT", "date_received": "6/30/2021", "respondent_name": "Houston Daugherty", "claimant_name": "Caden Vazquez", "last_updated": "", "sla_remaining": "28 days" },
   { "case_number": "IR2021-9726", "time_received": "5:32am MT", "date_received": "7/1/2021", "respondent_name": "Yuliana Oliver", "claimant_name": "Megan Mullins", "last_updated": "", "sla_remaining": "29 days" },
   { "case_number": "IR2021-9727", "time_received": "12:15pm MT", "date_received": "7/1/2021", "respondent_name": "Mikaela Acevedo", "claimant_name": "Duncan Singleton", "last_updated": "", "sla_remaining": "29 days" },
   { "case_number": "IR2021-9728", "time_received": "4:30pm MT", "date_received": "7/2/2021", "respondent_name": "Winston Wilson", "claimant_name": "Libby Hawkins", "last_updated": "", "sla_remaining": "30 days" },
   { "case_number": "IR2021-9729", "time_received": "6:15am MT", "date_received": "7/3/2021", "respondent_name": "Jacob Huynh", "claimant_name": "Javion Krueger", "last_updated": "", "sla_remaining": "30 days" }
 ];

  return(
    <div className="widgetWrapper fullWidth">
      <div className="wrapperHeader">New Incident Reports</div>

      <table className="dashboard_table widget hasAction">
        <thead>
          <tr>
            <th>Case #</th>
            <th>Time Received</th>
            <th>Date Received</th>
            <th>Respondent Name</th>
            <th>Claimant Name</th>
            <th>Last Updated</th>
            <th>SLA Remaining</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          { newIRs.map( (ir, i) => <tr key={i}>
            <td>{ ir.case_number }</td>
            <td>{ ir.time_received }</td>
            <td>{ ir.date_received }</td>
            <td>{ ir.respondent_name }</td>
            <td>{ ir.claimant_name }</td>
            <td>{ ir.last_updated }</td>
            <td>{ ir.sla_remaining }</td>
            <td>Assign…</td>
          </tr>)}
        </tbody>
      </table>
    </div>
  )
}


export default WidgetNewIntakes;
