import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'



interface FileList {
  name: string,
  mime_type: string,
  date_uploaded: string,
  uploaded_by: string,
  case_stage: string,
  tags: string,
  institution: string
}


function FileManagerComponent(props:any){
  if(props.type === "cases"){
    return (
      <div className="sectionWrapperFull">
        <section className="colFull media_library">
          <div className="wrapper">
            <div className="wrapperHeader">
              <div className="label">Case Files & Media Library</div>
              <div className="callout info_icon">Drop a file here to add to this library</div>
            </div>
            <div className="library_utilities">
              <div className="library_views">
                <div className="folder_view">Show Folder View</div>
                <div className="timeline_view">Show Timeline View</div>
              </div>
              <div className="file_search">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faSearch} /></span>
                  <div className="form-control placeholder" id="search_input" data-placeholder="Search case files…" contentEditable="true"></div>
                </div>
              </div>
            </div>
            <CaseFileManager fileList={ props.fileList } />
          </div>
        </section>
      </div>
    )
  }
  else{
    return (
      <div className="sectionWrapperFull">
        <section className="colFull media_library">
          <div className="wrapper">
            <div className="wrapperHeader">
              <div className="label">{ props.title }</div>
              <div className="callout info_icon">Drop a file here to add to this library</div>
            </div>
            <div className="library_utilities tighten_library_utilities">
              <div className="library_views"></div>
              <div className="file_search">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faSearch} /></span>
                  <div className="form-control placeholder" id="search_input" data-placeholder="Search case files…" contentEditable="true"></div>
                </div>
              </div>
            </div>
            <ResourcesFileManager fileList={ props.fileList } type={ props.type } />
          </div>
        </section>
      </div>
    )
  }
}




function CaseFileManager(props:any){
  let tabviews = [
    "All Media",
    "Interviews",
    "Transcripts",
    "Documentary Evidence",
    "Communications",
    "Formal Notices"
  ]

  function FileManagerTabs(props:any){

    function isActive(i:number){
      return i === props.active ? "fileManagerTab active" : "fileManagerTab";
    }

    return(
      <div className="fileManagerTabWrapper">
        { tabviews.map((tab:string, i:number) => <div key={"tab" + i} className={ isActive(i) } >{tab}</div>) }
      </div>
    )
  }

  function FileManagerList(props:any){
    return(
      <table className="fileManager">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Date Uploaded</th>
            <th>Uploaded By</th>
            <th>Case Stage</th>
            <th>Tags</th>
          </tr>
        </thead>
        <tbody>
          { props.fileList.map((file:FileList, i:number) => <tr key={ "file" + i } className={file.mime_type}><td className="filename">{ file.name }</td><td>{ file.mime_type }</td><td>{file.date_uploaded}</td><td>{file.uploaded_by}</td><td>{file.case_stage}</td><td>{file.tags}</td></tr> ) }
        </tbody>
      </table>
    )
  }

  const [tabview] = useState(0);

  return(
    <div className="caseFileWrapper">
      <FileManagerTabs active="0" tab={tabview} />
      <FileManagerList fileList={props.fileList} />
      <div className="createNewDocument">Create New Document…</div>
    </div>
  )
}

function ResourcesFileManager(props:any){
  let tabviews = [
    "All Media"
  ]

  function FileManagerTabs(props:any){

    function isActive(i:number){
      return i === props.active ? "fileManagerTab active" : "fileManagerTab";
    }

    return(
      <div className="fileManagerTabWrapper">
        { tabviews.map((tab:string, i:number) => <div className={ isActive(i) } >{tab}</div>) }
      </div>
    )
  }

  function FileManagerList(props:any){
    if(props.type === "policies"){
      return(
        <table className="fileManager">
          <thead>
            <th>Name</th>
            <th>Institution</th>
            <th>Date Uploaded</th>
            <th>Uploaded By</th>
          </thead>
          <tbody>
            { props.fileList.map((file:FileList) => <tr className={file.mime_type}><td className="filename">{ file.name }</td><td>{ file.institution }</td><td>{file.date_uploaded}</td><td>{file.uploaded_by}</td></tr> ) }
          </tbody>
        </table>
      )
    }
    else{
      return(
        <table className="fileManager">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Date Uploaded</th>
            <th>Uploaded By</th>
          </thead>
          <tbody>
            { props.fileList.map((file:FileList) => <tr className={file.mime_type}><td className="filename">{ file.name }</td><td>{ file.mime_type }</td><td>{file.date_uploaded}</td><td>{file.uploaded_by}</td></tr> ) }
          </tbody>
        </table>
      )
    }
  }

  const [tabview] = useState(0);

  return(
    <div className="caseFileWrapper">
      <FileManagerTabs active="0" tab={tabview} />
      <FileManagerList fileList={props.fileList} type={props.type} />
    </div>
  )
}


export default FileManagerComponent;
