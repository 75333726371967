import { ReactComponent as LogoWhite } from './logo_white.svg';

function LoggedOutState(props: any) {
  return (
    <main>
      <aside className="orange_wash">
        <div>
          <LogoWhite />
          <p>The SafeSport Case Management Application</p>
        </div>
      </aside>
      <div className="loginWrapper">
        <div className="loginBox">
          <div className="text">
            <div className="section_title">Welcome</div>
            <div className="section_caption">Not currently signed in</div>
            <form action="/login">
              <div className="inputGroup padTop">
                <label>Choose User</label>
                <select name="uid">
                  <option value="1">Mary Noname, administrator</option>
                  <option value="2">John Thamos, intake coordinator</option>
                  <option value="3">Oliver Tree, external investigator</option>
                </select>
              </div>
              <div className="buttonWrapper padTop">
                <input type="submit" value="Go" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}


export default LoggedOutState;
