function capitalize(s:string){
  return s.charAt(0).toUpperCase() + s.slice(1);
}


function isValidPhone(v:string){
  return /^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(v) || /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v);
}

function isValidEmail(s:string){
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(s);
}

function getUID(){
  let uid = 0;
  let uids = sessionStorage.getItem("uid");
  if(uids !== null){ uid = parseInt(uids, 10) }
  return uid;
}

function getUser(uid:number){
  let userSet = [
    {}, // empty first…sorry, Señor Zero
    { name: "Mary Noname", nickname: "Mary", role: "Administrator" },
    { name: "John Thamos", nickname: "JT", role: "Intake Coordinator" },
    { name: "Oliver Tree", nickname: "Ollie", role: "External Investigator" },
    { name: "Barry Noname", nickname: "Barry", role: "Preliminary Inquiry Investigator" }

  ]

  return userSet[uid];
}

function getUsername(uid:number){
  let u = getUser(uid);
  if(!!u.name){
    return u.name;
  }
  else{
    return "User not found!";
  }
}

function userCanEdit(caseData:any){
  return getUsername(getUID()) === caseData.current_assignment; // TODO: expand to other means of permissioning
}

function handleLogout(){
  sessionStorage.removeItem('uid');
  document.location.href = "/";
}


let caseEditTypes:any = {
  select_type_of_eligibility_action: "select_type_of_eligibility_action",
  respondent_name: "text",
  party_name: "text",
  respondent_dob: "date",
  respondent_ngb: "ngb",
  ngb: "ngb",
  ngb_affiliation: "ngb",
  respondent_role: "select_role",
  case_stage: "select_casestage",
  respondent_status: "select_respondentstatus",
  publish_status: "select_publishstatus",
  contact_date: "date",
  contact_method: "select_contactmethod",
  incident_summary: "textarea",
  date_of_jurisdictional: "date",
  jurisdictional_determination_type: "select_jurisdictional_determination_type",
  intake_assigned_to: "select_user_intake",
  intake_severity_of_allegations: "select_severity",
  pi_start_date: "date",
  pi_end_date: "date",
  pi_assigned_to: "select_user",
  pi_severity_of_allegations: "select_severity",
  pi_complexity: "select_complexity",
  pi_case_synopsis: "textarea",
  investigation_start_date: "date",
  investigation_end_date: "date",
  inv_assigned_to: "select_user_investigation",
  inv_severity_of_allegations: "select_severity",
  inv_complexity: "select_complexity",
  current_disposition: "select_currentdisposition",
  type_of_eligibility_action: "select_type_of_eligibility_action",
  date_of_disposition_issuance: "date",
  additional_dispositions: "text",
  applicable_policy_list: "text",
  published_misconduct_list: "text",
  no_contact_directive: "text"
}

let caseDataLabels:any = {
  respondent_name: "Respondent Name",
  party_name: "Verified Respondent Name",
  respondent_dob: "Respondent Date of Birth",
  respondent_ngb: "Respondent NGB",
  respondent_role: "Respondent Role",
  case_stage: "Case Stage",
  respondent_status: "Respondent Status",
  publish_status: "Publish Status",
  contact_date: "Contact Date",
  contact_method: "Contact Method",
  incident_summary: "Incident Summary",
  date_of_jurisdictional: "Date of Jurisdictional Determination",
  jurisdictional_determination_type: "Jurisdictional Determination Type:",
  intake_assigned_to: "Assigned To",
  intake_severity_of_allegations: "Severity of Allegations",
  pi_start_date: "Preliminary Inquiry Start Date",
  pi_end_date: "Preliminary Inquiry End Date",
  pi_assigned_to: "Assigned To",
  pi_severity_of_allegations: "Severity of Allegations (P.I.)",
  pi_complexity: "Complexity of Case (P.I.)",
  pi_case_synopsis: "Preliminary Inquiry Case Synopsis",
  investigation_start_date: "Investigation Start Date",
  investigation_end_date: "Investigation End Date",
  inv_assigned_to: "Assigned To (Investigations)",
  inv_severity_of_allegations: "Severity of Allegations (Investigations)",
  inv_complexity: "Complexity of Case (Investigations)",
  current_disposition: "Current Disposition",
  type_of_eligibility_action: "Type of Eligibility Action",
  date_of_disposition_issuance: "Date of Issuance",
  additional_dispositions: "Additional Disposition(s)",
  applicable_policy_list: "Applicable Policy",
  published_misconduct_list: "Published Misconduct",
  no_contact_directive: "No Contact Directive(s)"
}

let caseDataEmptyStates:any = {
  party_name: "Not yet verified",
  respondent_dob: "Unknown",
  respondent_ngb: "Unknown",
  respondent_role: "Unknown",
  respondent_status: "Unknown",
  publish_status: "Not published",
  incident_summary: "Not yet written",
  date_of_jurisdictional: "Unknown",
  jurisdictional_determination_type: "Not yet determined",
  intake_severity_of_allegations: "Not yet determined",
  pi_severity_of_allegations: "Not yet determined",
  pi_complexity: "Not yet determined",
  pi_case_synopsis: "Not yet written",
  inv_severity_of_allegations: "Not yet determined",
  inv_complexity: "Not yet determined",
  current_disposition: "Undetermined",
  type_of_eligibility_action: "No action at this time",
  additional_dispositions: "None",
  applicable_policy_list: "Not yet defined",
  published_misconduct_list: "None",
  no_contact_directive: "None"
}

function getDataFieldEmptyState(s:string){
  console.log(s);
  return caseDataEmptyStates[s];
}


export { capitalize, isValidPhone, isValidEmail, getUID, getUser, handleLogout, getUsername, userCanEdit, caseEditTypes, caseDataLabels, caseDataEmptyStates, getDataFieldEmptyState }
