import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faFileExport, faCommentAlt } from '@fortawesome/free-solid-svg-icons'
import MakeDataFieldOfTypes from './dataFieldsOfTypes';
import { capitalize } from './utilities';

function openCase(caseNumber:string) {
  let url = "/case/".concat(caseNumber);
  window.location.href = url;
}

interface CasesData {
  hasComment: boolean,
  caseNumber: string,
  respondentName: string,
  respondentNGB: string,
  respondentRole: string,
  incidentDate: string,
  case_stage: string,
  caseOwner: string,
  relatedCases: string[],
  lastUpdate: string,
  case_status: string
}


const casesdataset:CasesData[] = [
  { case_status: "open", hasComment: true, caseNumber: "2021-1827", respondentName: "George Evans", respondentNGB: "USA Swimming", respondentRole: "Athlete", incidentDate: "03/26/2021", case_stage: "Investigation", caseOwner: "Mary Noname", relatedCases: ["2020-0289"], lastUpdate: "4 days ago" },
  { case_status: "open", hasComment: true, caseNumber: "2021-1040", respondentName: "Sean Ipsum", respondentNGB: "USA Gymnastics", respondentRole: "Facility/Club/Gym Owner", incidentDate: "09/22/2020", case_stage: "Preliminary Inquiry", caseOwner: "Sandra DeFaké", relatedCases: [], lastUpdate: "9 days ago" },
  { case_status: "open", hasComment: true, caseNumber: "2021-2039", respondentName: "Samuel Maditup", respondentNGB: "USA Swimming", respondentRole: "Athlete", incidentDate: "08/26/2020", case_stage: "Investigation", caseOwner: "Mary Noname", relatedCases: [], lastUpdate: "11 days ago" },
  { case_status: "open", hasComment: true, caseNumber: "2021-2042", respondentName: "Harpish Nono", respondentNGB: "US Ski & Snowboard", respondentRole: "Coach", incidentDate: "05/11/2021", case_stage: "Preliminary Inquiry", caseOwner: "Anon Ymous", relatedCases: [], lastUpdate: "14 days ago" },
  { case_status: "open", hasComment: true, caseNumber: "2021-1999", respondentName: "Billy Fakename", respondentNGB: "USA Swimming", respondentRole: "Athlete", incidentDate: "03/26/2021", case_stage: "Investigation", caseOwner: "Mary Noname", relatedCases: [], lastUpdate: "20 days ago" },
  { case_status: "open", hasComment: false, caseNumber: "IR2021-2144", respondentName: "Anna Notreal", respondentNGB: "USA Tennis Association", respondentRole: "Coach", incidentDate: "04/30/2021", case_stage: "Intake", caseOwner: "Carrie Anotname", relatedCases: [], lastUpdate: "22 days ago" },
  { case_status: "open", hasComment: false, caseNumber: "2021-2077", respondentName: "Afake Lastname", respondentNGB: "USA Swimming", respondentRole: "Facility/Gym/Club Owner", incidentDate: "11/25/2020", case_stage: "Awaiting Assignment", caseOwner: "Mary Noname", relatedCases: ["2021-1032"], lastUpdate: "May 29" },
  { case_status: "closed", hasComment: false, caseNumber: "2021-0001", respondentName: "Randy Numero", respondentNGB: "USA Gymnastics", respondentRole: "Athlete", incidentDate: "7/2/2020", case_stage: "Disposition", caseOwner: "Mary Noname", relatedCases: ["2020-8372"], lastUpdate: "Feb 14" },
  { case_status: "closed", hasComment: false, caseNumber: "2021-0003", respondentName: "Mario Notsupero", respondentNGB: "USA Gymnastics", respondentRole: "Coach", incidentDate: "5/11/2020", case_stage: "Disposition", caseOwner: "Mary Noname", relatedCases: [], lastUpdate: "Jan 22" }
];


function getCases(props:any){
  if(props.case_status == "open"){
    return casesdataset.filter(function(o){return o.case_status == "open";} )
  }
  if(props.case_status == "closed"){
    return casesdataset.filter(function(o){return o.case_status == "closed";} )
  }
  else{
    return casesdataset;
  }
}

interface FormElements extends HTMLFormControlsCollection {
    yourInputName: HTMLInputElement
}

interface YourFormElement extends HTMLFormElement {
   readonly elements: FormElements
}


function Cases(props:any) {
  let [filters, updateFilters] = useState({case_status: "open", sortby: "date"});
  let [currentCasesView, updateCurrentCasesView] = useState(getCases(filters));
  let [filterChange, setFilterChange] = useState(false);
  let [modalOpen,setModalOpen] = useState(false);

  function setModalIsOpenToTrue(){
    setModalOpen(true);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // Preventing the page from reloading
    e.preventDefault();
    let newFilters = {...filters};
    let newvalue = (document.getElementsByName("case_status")[0] as HTMLInputElement).value || filters.case_status;
    newFilters.case_status = newvalue
    updateFilters(newFilters)
    updateCurrentCasesView(getCases(newFilters));
    setModalOpen(false);
  }


  function CaseRow(props:any){
    return (
      <tr key={ props.n } className={ props.hasComment ? "featured" : "" } onClick={(event: React.MouseEvent<HTMLElement>) => { openCase(props.caseNumber) }}>
        <td><input type="checkbox" /></td>
        <td><FontAwesomeIcon icon={faCommentAlt} /></td>
        <td className="case_number">{ props.caseNumber }</td>
        <td className="respondent_name">{ props.respondentName }</td>
        <td>{ props.respondentNGB }</td>
        <td>{ props.respondentRole }</td>
        <td>{ props.incidentDate }</td>
        <td>{ props.case_stage }</td>
        <td>{ props.caseOwner }</td>
        <td></td>
        <td>{ props.lastUpdate }</td>
      </tr>
    );
  }


  return (
    <section>
      <div className="text twoColumn">
        <div className="textWrapper">
          <div className="section_title">Case Management</div>
          <div className="section_caption">You have 5 case updates to review</div>
        </div>
        <div className="actions">
          <div className="addNewButton">
            <a href="/case/create" className="button add_new_case">Create New Case</a>
          </div>
        </div>
      </div>
      <div className="dashboard">

        <div className="controls">
          <div className="control_state">
            { capitalize(filters.case_status) } Cases, Assigned to Anyone, All Dates
            <div className="control_state_change" onClick={ setModalIsOpenToTrue }>Tap to Change Filters</div>
          </div>
          <div className="control" id="showClosed">
            <div className="control_icon"><FontAwesomeIcon icon={faFileExport} /></div>
            Export
          </div>
          <div className="control active" id="configureView">
            <div className="control_icon"><FontAwesomeIcon icon={faClone} /></div>
            Batch Update
          </div>
          </div>
        </div>

        <div className="table_holder">
          <table className="dashboard_table hasAction">
            <thead>
              <tr className="tablehead">
                <th className="checkheader"><input type="checkbox" id="checkall" /></th>
                <th></th>
                <th>Case #</th>
                <th>Respondent Name</th>
                <th>Respondent NGB</th>
                <th>Respondent Role</th>
                <th>Incident Date</th>
                <th>Case Stage</th>
                <th>Case Owner</th>
                <th>Related Cases</th>
                <th>Last Update</th>
              </tr>
            </thead>
            <tbody>
              { currentCasesView.map((cd:CasesData, i:number) => <CaseRow key={ i } hasComment={cd.hasComment} caseNumber={cd.caseNumber} respondentName={cd.respondentName} respondentNGB={cd.respondentNGB} respondentRole={cd.respondentRole} incidentDate={cd.incidentDate} case_stage={cd.case_stage} caseOwner={cd.caseOwner} lastUpdate={cd.lastUpdate} />)}
            </tbody>
          </table>
        </div>




        { modalOpen && (
          <div className="modalOverlay">
            <form className="modalContent" onSubmit={ handleSubmit }>
              <button className="modalClose" onClick={ (e) => setModalOpen(false)}></button>
              <div className="modalHeader">Change Filters</div>
              <div className="filtersWrapper">
                <div className="dataSet col25">
                  <div className="dataHeader">Case Type</div>
                  <select className="dataFilter" name="case_type">
                    <option value="any">Any</option>
                    <option value="case">Case</option>
                    <option value="incident_report">Incident Report</option>
                  </select>
                </div>
                <div className="dataSet col25">
                  <div className="dataHeader">Case Status</div>
                  <select className="dataFilter" name="case_status">
                    <option value="any">Any</option>
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>
                <div className="dataSet col50">
                  <div className="dataHeader">Case Owner</div>
                  <input type="text" placeholder="Last name" className="dataFilter" name="case_owner" />
                </div>
              </div>
              <div className="filtersWrapper">
                <div className="dataSet col50">
                  <div className="dataHeader">Respondent NGB Affiliation</div>
                  <MakeDataFieldOfTypes field="ngb" initialValue="" className="dataFilter" />
                </div>
                <div className="dataSet col25">
                  <div className="dataHeader">Case Creation Date Range</div>
                  <input type="date" className="dataFilter" name="creation_date_from" placeholder="From" /><br />
                  <input type="date" className="dataFilter" name="creation_date_to" placeholder="To" />
                </div>
              </div>
              <div className="filtersWrapper">
                <div className="dataSet col50">
                  <div className="dataHeader">Eligibility Actions Disposition</div>
                  <MakeDataFieldOfTypes field="select_type_of_eligibility_action" initialValue="" className="dataFilter" />
                </div>
              </div>
              <div className="filtersWrapper">
                <div className="dataSet col25">
                  <div className="dataHeader">Case Stage</div>
                  <MakeDataFieldOfTypes field="case_stage" initialValue="" className="dataFilter" />
                </div>
                <div className="dataSet col25">
                  <div className="dataHeader">Case Outcome</div>
                  <MakeDataFieldOfTypes field="current_disposition" initialValue="" className="dataFilter" />
                </div>
              </div>
              <div className="caseDetails actions spacing_top_large">
                <button className="button no_icon">Search Cases</button><input type="reset" className="button no_icon spacing_left_large" value="Reset Search" />
              </div>
            </form>
          </div>)
        }

    </section>
  );
}


export default Cases;
