import React, { useState } from "react";
import {isValidEmail, isValidPhone} from "./utilities";

function makeHref(s:string){
  if(isValidEmail(s)){
    return (
      <a href={ "mailto:" + s }>{ s }</a>
    )
  }
  else if(isValidPhone(s)){
    return(
      <a href={ "tel:" + s }>{ s }</a>
    )
  }
  else{
    return(
      s
    );
  }
}

// Component accept text, placeholder values and also pass what type of Input - input, textarea so that we can use it for styling accordingly
const Editable = ({
  text,
  type,
  placeholder,
  children,
  ...props
}:any) => {
  // Manage the state whether to show the label or the input box. By default, label will be shown.
// Exercise: It can be made dynamic by accepting initial state as props outside the component
  const [isEditing, setEditing] = useState(false);

// Event handler while pressing any key while editing
  const handleKeyDown = (event:any, type:any) => {
    // Handle when key is pressed
  };

return (
    <>
      { isEditing ? (
        <div
          onKeyDown={e => handleKeyDown(e, type)}
          onBlur={ () => setEditing(false) }
        >
          {children}
          <span className="caption" onClick={ () => setEditing(false) }>Cancel</span>
        </div>
      ) : isValidEmail(text) || isValidPhone(text) ? (
        <div>
          { makeHref(text) } <span className="caption" onClick={() => setEditing(true)}>Edit</span>
        </div>
      ) :
      (
        <div>
          <span>
            {text || placeholder}
          </span> <span className="caption" onClick={() => setEditing(true)}>Edit</span>
        </div>
      )}
    </>
  );
};

export default Editable;
