import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ReactTimeAgo from 'react-time-ago'

import { useState } from "react";
import { getUID } from "./utilities";


TimeAgo.addDefaultLocale(en)

interface ITodo{
  id: number,
  case_number: string,
  note: string,
  due_date: string,
  date_created: any,
  created_by: number,
  status: string
}

function TaskList(props:any) {
  const [tasks, updateTasks] = useState([
   { id: 0, "case_number": "IR2021-9302", "note": "Update contact information", "due_date": "2021-09-09", date_created: "2021-08-26", "created_by": 0, "status": "active" },
   { id: 1, "case_number": "IR2021-7212", "note": "Check on certified mail response", "due_date": "2021-09-10", date_created: "2021-09-01", "created_by": 3, "status": "active" },
   { id: 2, "case_number": "IR2021-7212", "note": "Match legal name with intake name", "due_date": "2021-09-10", date_created: "2021-09-02", "created_by": 4, "status": "active" },
   { id: 3, "case_number": "IR2021-7402", "note": "Update contact information", "due_date": "2021-09-15", date_created: "2021-09-02", "created_by": 6, "status": "active" },
   { id: 4, "case_number": "IR2021-6699", "note": "Resend member records request", "due_date": "2021-09-17", date_created: "2021-09-02", "created_by": 3, "status": "active" },
   { id: 5, "case_number": "IR2021-6699", "note": "Update contact information", "due_date": "2021-09-21", date_created: "2021-09-02", "created_by": 2, "status": "active" }
 ]);

 function handleDelete(task:number){
   task = task * 1; // stupid type issue
   let copy = [... tasks];
   for(let x=0; x<copy.length; x++){
     if(task === copy[x].id){
       copy[x].status = copy[x].status === "completed" ? "active" : "completed";
     }
   }
   updateTasks(copy);
 }

 function updateTask(task:ITodo){
   let copy = [... tasks];
   for(let x=0; x<copy.length; x++){
     if(task.id === copy[x].id){
       copy[x] = task;
     }
   }
   updateTasks(copy);
 }

 function makeNewID(){
    let high = 0;
    for(let x=0;x<tasks.length; x++){
      if(tasks[x].id >= high){
        high = tasks[x].id;
      }
    }
    return (high+1);
  }

  function addTask(o:any){
    o.id = makeNewID();
    o.date_created = new Date().toISOString().split("T")[0];
    o.created_by = getUID();
    o.status = "active";
    let copy = [... tasks];
    copy.push(o);
    updateTasks(copy);
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    let newtasknote = (document.getElementsByName("new_note")[0] as HTMLInputElement).value;
    let newcasenumber = (document.getElementsByName("new_case_number")[0] as HTMLSelectElement).value;
    let newdate = (document.getElementsByName("new_due_date")[0] as HTMLInputElement).value;

    let ableToSubmit = newtasknote !== "" && newcasenumber !== "" && newdate !== "";

    if(ableToSubmit) {
      const newtask:ITodo = {
        id: makeNewID(),
        created_by: props.uid,
        note: newtasknote.trim(),
        date_created: new Date().getTime(),
        status: "active",
        due_date: newdate.trim(),
        case_number: newcasenumber
      };
    }
  };

  return (
    <div className="widgetWrapper">
      <div className="wrapperHeader">My Tasks</div>



    {tasks.length > 0 && (
      <table className="dashboard_table widget dashboard_table_clean">
        <thead>
          <tr>
            <th></th>
            <th>Case #</th>
            <th>Notes</th>
            <th>Due Date</th>
            <th className="taskActions"></th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((todo) => (
            <TodoItem key={todo.id} todo={todo} mycases={ props.mycases } func={ handleDelete } />
          ))}
          <AddNewTask mycases={ props.mycases } addTask={ addTask } />
        </tbody>
      </table>
      )}

    </div>
  );
};

interface TodoItemProps {
  todo: ITodo,
  mycases: any,
  func: Function
}

function AddNewTask(props: any){
  let [isEditing, setIsEditing] = useState(false);
  let [caseNumber, setCaseNumber] = useState("");
  let [caseDueDate, setCaseDueDate] = useState(new Date().toISOString().split("T")[0])
  let [input, setInput] = useState("");

  function toggleEdit(){
    setIsEditing(!isEditing);
  }

  function submitNewTask(){
    let canPost = true;
    if(!!input){
      props.addTask({ case_number: caseNumber, note: input, due_date: caseDueDate });
      setCaseNumber("");
      setCaseDueDate(new Date().toISOString().split("T")[0]);
      setInput("");
      setIsEditing(false);
    }

  }

  if(isEditing){
    return(
      <tr className="newtask">
        <td><button value="new" className="taskAction taskStatus"></button></td>
        <td className="taskCaseNumber">
          <select className="dataField" name="new_task_case_number" value={ caseNumber }
          onChange={(e) => setCaseNumber(e.target.value)}>
            { props.mycases.map((cn:any, i:number) => <option key={"opt"+i} value={ cn.case_number } selected={ cn.case_number === caseNumber ? true : false } >{ cn.case_number }</option> ) }
          </select>
        </td>
        <td className="taskDetail"><input
          className="dataField"
          value={ input }
          onChange={(e) => setInput(e.target.value)}
          type="text"
          name="new_task_note"
          id="new_take_note"
          autoComplete="off"
          />
        </td>
        <td><input type="date"
          name="task_due_date"
          className="dataField taskDate"
          value={ caseDueDate }
          onChange={(e) => setCaseDueDate(e.target.value)}
          />
        </td>
        <td className="taskActions"><button onClick={ toggleEdit } className="taskAction taskEdit"></button><button onClick={ submitNewTask } className="taskAction taskConfirm"></button></td>
      </tr>
    )
  }
  else{
    return(
      <tr>
        <td><button onClick={ toggleEdit } className="taskAction taskStart"></button></td>
      </tr>
    )
  }
}







const TodoItem: React.FC<TodoItemProps> = ({ todo, mycases, func }) => {
  let og = todo.note;
  let id = todo.id;
  let [isEditing, setIsEditing] = useState(false);
  let [caseNumber, setCaseNumber] = useState(todo.case_number);
  let [input, setInput] = useState(todo.note);

  const handleToggle = () => {
    const copy = { ...todo };
    copy.status = copy.status === "active" ? "completed" : "active";
  };

  const handleEdit = () => {
    if (!!input.trim()) {
      const copy = { ...todo };
      copy.note = input.trim();
    } else {
      setInput(og);
    }
    setIsEditing(false);
  };

  function toggleEdit(){
    if(todo.status === "active"){
      setIsEditing(!isEditing);
    }
  }

  function toggleComplete(e:any){
    let id = e.target.value;
    func(id);
  }

  function handleCaseNumberChange(e:any){
    let newcasenumber = e.target.value;
    setCaseNumber(newcasenumber);
  }

  if(isEditing){
    return(
      <tr className={ todo.status }>
        <td><button value={ todo.id } onClick={ toggleComplete } className="taskAction taskStatus"></button></td>
        <td className="taskCaseNumber">
          <select className="dataField" onChange={ handleCaseNumberChange }>
            { mycases.map((cn:any, i:number) => <option key={"opt"+i} value={ cn.case_number } selected={ cn.case_number === caseNumber ? true : false } >{ cn.case_number }</option> ) }
          </select>
        </td>
        <td className="taskDetail"><input
          className="dataField subtle"
          value={ input }
          onChange={(e) => setInput(e.target.value)}
          name="todo-input"
          id="todo-input"
          autoComplete="off"
          />
        </td>
        <td><input type="date" value={ todo.due_date } name="task_due_date" className="dataField taskDate" /></td>
        <td className="taskActions"><button onClick={ toggleEdit } className="taskAction taskEdit"></button><button onClick={ handleEdit } className="taskAction taskConfirm"></button></td>
      </tr>
    )
  }
  else{
    return(
      <tr className={ todo.status }>
        <td><button value={ todo.id } onClick={ toggleComplete } className="taskAction taskStatus"></button></td>
        <td className="taskCaseNumber" onClick={ toggleEdit }>{ caseNumber }</td>
        <td onClick={ toggleEdit }>{ input }</td>
        <td onClick={ toggleEdit }><ReactTimeAgo date={ todo.due_date } locale="en-US" /></td>
        <td className="taskActions"></td>
      </tr>
    )
  }
};

export default TaskList;
