import React, {useState} from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Editable from './editable';
import { isValidPhone, isValidEmail, userCanEdit, caseEditTypes, caseDataLabels, getDataFieldEmptyState } from './utilities';
import FileManagerComponent from './filemanager';
import MakeDataFieldOfTypes from './dataFieldsOfTypes';
import { getIR, makeCaseData } from './fauxdata'

interface caseDataStructure {
  respondent_name: string,
  case_status: string,
  current_assignment: string,
  current_case_stage: string,
  party_name: string,
  respondent_dob: string,
  respondent_ngb: string,
  respondent_role: string,
  case_stage: string,
  respondent_status: string,
  publish_status: string,
  contact_date: string,
  contact_method: string,
  incident_summary: string,
  date_of_jurisdictional: string,
  jurisdictional_determination_type: string,
  intake_assigned_to: string,
  intake_severity_of_allegations: string,
  pi_start_date: string,
  pi_end_date: string,
  pi_assigned_to: string,
  pi_severity_of_allegations: string,
  pi_complexity: string,
  pi_case_synopsis: string,
  investigation_start_date: string,
  investigation_end_date: string,
  inv_assigned_to: string,
  inv_severity_of_allegations: string,
  inv_complexity: string,
  current_disposition: string,
  type_of_eligibility_action: string,
  date_of_disposition_issuance: string,
  additional_dispositions: string,
  applicable_policy_list: string, // TODO: Array
  published_misconduct_list: string, // TODO: Array
  no_contact_directive: string,
  allegation_categories: string[],
  tasks: TaskStructure[],
  case_history: HistoryDetail[],
  flags: FlagDetail[],
  related_cases: string[],
  case_personnel: Personnel[]
}

interface FlagDetail {
  flag: string,
  date: string,
  alert: string,
  url: string
}

interface TaskStructure {
  detail: string,
  assigned_to: string,
  due_date: string
}

interface HistoryDetail {
  occurrence: string,
  dateOccurred: string,
  annotation: string
};

interface PartyDetail {
  name: string,
  role: string,
  annotation: string,
  phone: string,
  updatingPhone?: string,
  email: string,
  updatingEmail?: string
}


interface Personnel {
  role: string,
  name: string
}





interface IncidentParty {

}



interface Incident {
  id: string,
  number: string,
  case: Case,
  reporterWantsFollowUp: Boolean,
  contactMethod: string,
  startedAt: any,
  finishedAt: any,
  details: string,
  concernsChildAbuse: Boolean,
  childAbuseConcernsReportedToLawEnforcement: Boolean,
  parties: IncidentParty[],
  attachments?: string[]
}

interface Case {
  id: string,
  number: string,
  claimant: Person,
  respondent: Person,
  stage: string,
  assignee: number,
  relatedCases: [Case],
  relatedClaimants: Person[],
  witnesses: Person[],
  reporter: Person,
  allegationCategories: string[],
  resolution: string,
  investigation: string,
  incidents: Incident[],
  closedAt?: any
}


interface PhoneNumber {
  number: string,
  phoneType?: string
}

interface NGB {
  id: string,
  institution: string
}

interface Person {
  id: string,
  name: string,
  aliases: string[],
  email?: string,
  phoneNumbers: [PhoneNumber],
  ngbs: [NGB]
}







function CaseNumber():string{
  let p = document.location.pathname;
  let case_number = p.replace("/case/", "");
  return case_number;
}

function CaseCap() {
  return (
    <section className="no_bottom_border">
      <div className="caseHeader">
        <div className="back"><a href="/cases"><FontAwesomeIcon icon={faArrowLeft} color="#283b5a" /></a></div><div className="caseNumber section_title">Case {CaseNumber()}</div>
      </div>
    </section>
  );
}

function CaseHeader(props:any){

  function caseNotesLink(){
    return "/notes/" + CaseNumber();
  }

  return (
    <div className="sectionWrapper">
      <section className="colLeft">
        <div className="textWrapper">
          <div className="section_title">{ props.caseData.respondent_name }, { props.caseData.respondent_role } at { props.caseData.respondent_ngb }</div>
          <div className="section_caption">Last updated at 9:06am, Thursday June 26, 2021</div>
        </div>
      </section>
      <section className="colRight">
        <div className="buttonWrapper">
          <a href={ caseNotesLink() } className="button case_notes">View Case Notes</a>
          <div className="button case_share">Share Case</div>
          <div className="button case_print">Print/Export</div>
        </div>
      </section>
    </div>
  );
}




function CaseHistory(props:any){

  function getAnnotationStyles(h:HistoryDetail){
    return "history twoColumn " + h.annotation;
  }

  // TODO: replace with props once the AJAX is in place
  let casehistoryobject = {
    age: "61 Days",
    histories: [
      { occurrence: "Form Received", dateOccurred: "4/10/2021", annotation: "" },
      { occurrence: "Intake", dateOccurred: "4/11/2021", annotation: "" },
      { occurrence: "Assigned to Aname Thatsfake", dateOccurred: "4/14/2021", annotation: "assignment" },
      { occurrence: "Preliminary Inquiry", dateOccurred: "4/20/2021", annotation: "" },
      { occurrence: "Assigned to Barry Madeup", dateOccurred: "4/21/2021", annotation: "assignment" },
      { occurrence: "Severity of Allegations Change", dateOccurred: "4/22/2021", annotation: "" },
      { occurrence: "Jurisdictional Determination", dateOccurred: "5/20/2021", annotation: "" },
      { occurrence: "Law Enforcement Hold", dateOccurred: "5/20/2021", annotation: "" },
      { occurrence: "Investigation", dateOccurred: "5/22/2021", annotation: "" },
      { occurrence: "Assigned to Mary Noname", dateOccurred: "5/23/2021", annotation: "assignment" },
      { occurrence: "Law Enforcement Hold Lifted", dateOccurred: "5/30/2021", annotation: "" },
      { occurrence: "Disposition", dateOccurred: "Not started", annotation: "notStarted" }
    ]
  }

  casehistoryobject.histories = props.caseData.case_history;


  return (
    <div className="historyWrapper rightWrapper">
      <div className="wrapperHeader">
        <div>History <span className="parenthetical">(Case Age: { casehistoryobject.age })</span></div>
      </div>
      {casehistoryobject.histories.map( (hist, i:number) => (
        <div className={getAnnotationStyles(hist)} key={ "history" + i }>
          <div className="occurance">{ hist.occurrence }</div>
          <div className="dateOccurred">{ hist.dateOccurred }</div>
        </div>
      ))}
    </div>
  );
}

function NewPartyAvailableSelect(props:any){
  let needClaimant = true;
  for(let x=0; x<props.parties.length; x++){
    console.info(props.parties[x]);
    if(props.parties[x].role === "Claimant"){
      needClaimant = false;
      break;
    }
  }
  console.info(needClaimant);
  return(
    <select className="dataField" name="new_party_relationship">
      { needClaimant ? (<option value="claimant">Claimant</option>) : "" }
      <option value="witness">Witness</option>
      <option value="advisor">Advisor</option>
    </select>
  )
}

function NewParty(props:any){
  let [newparty, addnewparty] = useState({
    name: "",
    role: "",
    email: "",
    phone: ""
  });

  function collectNewParty(){
    let npn = (document.getElementsByName("new_party_name")[0] as HTMLInputElement).value;
    let npr = (document.getElementsByName("new_party_relationship")[0] as HTMLSelectElement).value;
    let npp = (document.getElementsByName("new_party_contact_phone")[0] as HTMLInputElement).value;
    let npe = (document.getElementsByName("new_party_contact_email")[0] as HTMLInputElement).value;

    let ableToSubmit = true;

    if(npn === ""){
      // invalid — TODO add other invalid states
      document.getElementsByName("new_party_name")[0].classList.add("error");
      ableToSubmit = false;
    }
    else{
      document.getElementsByName("new_party_name")[0].classList.remove("error");
    }

    if(!isValidEmail(npe)){
      // invalid
      document.getElementsByName("new_party_contact_email")[0].classList.add("error");
      ableToSubmit = false;
    }
    else{
      document.getElementsByName("new_party_contact_email")[0].classList.remove("error");
    }

    if(!isValidPhone(npp)){
      // invalid
      document.getElementsByName("new_party_contact_phone")[0].classList.add("error");
      ableToSubmit = false;
    }
    else{
      document.getElementsByName("new_party_contact_phone")[0].classList.remove("error");
    }
  }

  return(
    <tr className="add_new">
      <td className="party_name"><input type="text" className="dataField" name="new_party_name" placeholder="Full name" /></td>
      <td>
        <NewPartyAvailableSelect parties={ props.parties } />
      </td>
      <td><input type="text" className="dataField" name="new_party_contact_phone" placeholder="Phone" /></td>
      <td><input type="text" className="dataField" name="new_party_contact_email" placeholder="Email address" /></td>
      <td className="actions">
        <button className="text_button">Submit</button>
      </td>
    </tr>
  )
}


function CasePartyList(props:any){
  let newparties:PartyDetail[] = [];
  props.caseData.parties.forEach((val:any) => newparties.push(Object.assign({}, val)));
  // let newparties:PartyDetail[] = [...props.caseData];
  let [parties,updateParties] = useState(newparties);

  function updatePhone(name:string, value:any){
    let updatedParties:PartyDetail[] = [];
    for(let x=0; x<parties.length;x++){
      updatedParties[x] = parties[x];
      if(parties[x].name === name){
        updatedParties[x].updatingPhone = value;
      }
    }
    updateParties(updatedParties);
  }

  function updateEmail(name:string, value:any){
    let updatedParties:PartyDetail[] = [];
    for(let x=0; x<parties.length;x++){
      updatedParties[x] = parties[x];
      if(parties[x].name === name){
        updatedParties[x].updatingEmail = value;
      }
    }
    updateParties(updatedParties);
  }

  function updatePartiesFromUpdatingPhone(name:string){
    let trulyNewParties:PartyDetail[] = [];
    for(let x=0; x<parties.length;x++){
      trulyNewParties[x] = parties[x];
      if(parties[x].name === name){
        trulyNewParties[x].phone = parties[x].updatingPhone || parties[x].phone;
      }
    }
    console.info("Send mutation, let the response actually update the state variable");
    updateParties(trulyNewParties);
  }

  function updatePartiesFromUpdatingEmail(name:string){
    let trulyNewParties:PartyDetail[] = [];
    for(let x=0; x<parties.length;x++){
      trulyNewParties[x] = parties[x];
      if(parties[x].name === name){
        trulyNewParties[x].email = parties[x].updatingEmail || parties[x].email;
      }
    }
    console.info("Send mutation, let the response actually update the state variable");
    updateParties(trulyNewParties);
  }

  function onKeyPressedPhone(name: string, e:any){
    if(e.keyCode === 13){
      if(isValidPhone(e.target.value)){
        updatePartiesFromUpdatingPhone(name);
        e.target.blur();
      }
      else{
        e.target.classList.add("error")
      }
    }
  }

  function onKeyPressedEmail(name: string, e:any){
    if(e.keyCode === 13){
      if(isValidEmail(e.target.value)){
        updatePartiesFromUpdatingEmail(name);
        e.target.blur();
      }
      else{
        e.target.classList.add("error")
      }
    }
  }

  return(
    <div>
      { props.showHeader && <div className="modalHeader">Contact Details</div> }
      <table className="dashboard_table parties_table">
        <thead>
          <tr className="tablehead">
            <th>Name</th>
            <th>Relationship</th>
            <th>Phone #</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        { newparties.map( (p:PartyDetail, i:number) => (
            <tr className={ p.annotation } key={ p.name }>
              <td className="party_name">{ p.name }</td>
              <td className="party_relationship">{ p.role }</td>
              <td className="fixedWidthTD30P">
                <Editable text={p.phone} type="input">
                  <input type="text" className="dataField marginRight" placeholder={ p.phone } onChange={e => updatePhone(p.name, e.target.value)} onKeyDown={ e => onKeyPressedPhone(p.name, e) }/>
                </Editable>
              </td>
              <td className="fixedWidthTD30P">
                <Editable text={p.email} type="input">
                  <input type="text" className="dataField marginRight" placeholder={ p.email } onChange={e => updateEmail(p.name, e.target.value)} onKeyDown={ e => onKeyPressedEmail(p.name, e) }/>
                </Editable>
              </td>
              <td className="actions">
                <button className="text_button">Remove</button>
              </td>
            </tr>
          ))
        }
        </tbody>
        { props.showAdd ? (
          <>
            <tr className="tr_separator">
              <td colSpan={5}>Add a New Party</td>
            </tr>
            <NewParty parties={props.caseData.parties} />
          </>
        ) : "" }

      </table>
    </div>
  )
}

function getAnnotationStyles(p:PartyDetail){
  return "party twoColumn " + p.annotation;
}

function CaseParties(props:any){
  const [modalIsOpen,setModalIsOpen] = useState(false);
  const [modalShowsAdd, setModalShowsAdd] = useState(false);
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false)
  }
  function makeOpenModal(){
    setModalIsOpen(true);
    setModalShowsAdd(false);
  }
  function addNewParty(){
    setModalIsOpen(true);
    setModalShowsAdd(true);
  }

  let caseparties = props.caseData.parties;

  return (
    <div className="partiesWrapper rightWrapper">
      <Modal appElement={ document.body } parentSelector={() => document.body} overlayClassName="modalOverlay" className="modalContent" isOpen={modalIsOpen} onRequestClose={()=> setModalIsOpen(false)}>
          <button className="modalClose" onClick={setModalIsOpenToFalse}></button>
          <CasePartyList caseData={ props.caseData } showAdd={ modalShowsAdd } showHeader={ true } />
      </Modal>
      <div className="wrapperHeader twoColumn">
        <div>Parties</div>
        <div className="wrapperHeaderAction" onClick={ makeOpenModal }>View/Edit Contact Information</div>
      </div>
      {caseparties.map( (party:PartyDetail, i:number) => (
        <div className= { getAnnotationStyles(party) } key={"parties" + i}>
          <div className="partyRole">{ party.role }</div>
          <div className="partyName hasCallout">{ party.name }</div>
        </div>
      ))}
      <div className="party twoColumn createParty">
        <div className="partyDetail" onClick={ addNewParty }>Add New Party</div>
      </div>
    </div>
  );
}



function CasePersonnel(props:any){

  // TODO: replace with props once the AJAX is in place
  let casepersonnel = [
    { role: "Intake Coordinator", name: "Aname Thatsfake" },
    { role: "PI Investigator", name: "Barry Madeup" },
    { role: "Investigator", name: "Mary Noname" },
    { role: "Legal", name: "Stephen C. Bowles" }
  ]

  casepersonnel = props.caseData.case_personnel;

  return (
    <div className="personnelWrapper rightWrapper">
      <div className="wrapperHeader twoColumn">
        <div>Personnel</div>
      </div>
      {casepersonnel.map( (personnel, i:number) => (
        <div className="personnel twoColumn" key={ "personnel" + i}>
          <div className="personnelRole">{ personnel.role }</div>
          <div className="personnelName hasCallout">{ personnel.name }</div>
        </div>
      ))}
    </div>
  );
}

function CaseAllegations(props:any){

  // TODO: replace with props once the AJAX is in place
  let caseallegationslist = [
    "Inappropriate Conduct of Sexual Nature - Adult to Adult", "Sexual Misconduct - Adult to Adult"
  ]

  caseallegationslist = props.caseData.allegation_categories;
  if(props.caseData.allegation_categories.length){
    return (
      <div className="allegationsWrapper rightWrapper">
        <div className="wrapperHeader">Allegation Categories</div>
        {caseallegationslist.map( (allegation, i:number) => (
          <div key={ "allegation" + i} className="allegation">{ allegation }</div>
        ))}
      </div>
    );
  }
  return (
    <div className="allegationsWrapper rightWrapper">
      <div className="wrapperHeader">Allegation Categories</div>
      <div className="allegation">None at this time</div>
    </div>
  );

}








function CaseWidgetCurrentDisposition(props: any) {

  function getItemLabel(o:string){
    return props.caseDataLabels[o];
  }

  function getCaseData(o:string){
    return props.caseData[o];
  }

  let currentDispositionDataLeft:string[] = ["current_disposition", "type_of_eligibility_action", "date_of_disposition_issuance", "additional_dispositions"];
  let currentDispositionDataRight:string[] = ["applicable_policy_list", "published_misconduct_list", "no_contact_directive"];

  return(
    <div className="leftWrapper case_current_disposition">
      <div className="wrapperHeader">Current Disposition</div>
      <div className="caseDetails twoColumn">

        <div className="dataSet">
          { currentDispositionDataLeft.map((item:string) => <div key={item}> <div className="dataHeader">{ getItemLabel(item) }:</div><div className="dataField">{getCaseData(item)}</div> </div> )}
        </div>
        <div className="dataSet">
          { currentDispositionDataRight.map((item:string) => <div key={item}> <div className="dataHeader">{ getItemLabel(item) }:</div><div className="dataField">{getCaseData(item)}</div> </div> )}
        </div>
      </div>
    </div>
  )
}


function CaseWidgetIntake(props: any) {

  function getItemLabel(o:string){
    return props.caseDataLabels[o];
  }

  function getCaseData(o:string){
    return props.caseData[o];
  }

  function doSomethingAboutChange(){
    console.info("Okay, like this?");
  }

  let investigationDataLeft:string[] = ["investigation_start_date", "investigation_end_date"];
  let investigationDataRight:string[] = ["inv_assigned_to", "inv_severity_of_allegations", "inv_complexity"];

  if(props.editable){ // TODO: implement React Editable
    return(
      <form className="leftWrapper case_stage investigation" onChange={ doSomethingAboutChange }>
        <div className="wrapperHeader">Investigation</div>
        <div className="caseDetails twoColumn">

          <div className="dataSet">
            { investigationDataLeft.map((item:string) => <div key={item}> <div className="dataHeader">{ getItemLabel(item) } (Editable):</div><input type="text" className="dataField" defaultValue={getCaseData(item)} /></div> )}
          </div>
          <div className="dataSet">
            { investigationDataRight.map((item:string) => <div key={item}> <div className="dataHeader">{ getItemLabel(item) }:</div><div className="dataField">{getCaseData(item)}</div> </div> )}
          </div>
        </div>
      </form>
      )
    }
    else{
      return(
      <div className="leftWrapper case_stage investigation">
        <div className="wrapperHeader">Investigation</div>
        <div className="caseDetails twoColumn">

          <div className="dataSet">
            { investigationDataLeft.map((item:string) => <div key={item}> <div className="dataHeader">{ getItemLabel(item) }:</div><div className="dataField">{getCaseData(item)}</div> </div> )}
          </div>
          <div className="dataSet">
            { investigationDataRight.map((item:string) => <div key={item}> <div className="dataHeader">{ getItemLabel(item) }:</div><div className="dataField">{getCaseData(item)}</div> </div> )}
          </div>
        </div>
      </div>
    )
  }
}


function CaseStage(props:any){

  let introDataLeft:string[] = ["party_name", "respondent_dob", "respondent_ngb", "respondent_role"];
  let introDataRight:string[] = ["respondent_status", "publish_status"];
  let intakeDataTopLeft:string[] = ["contact_date"];
  let intakeDataTopRight:string[] = ["contact_method"];
  let intakeDataMiddle:string[] = ["incident_summary"];
  let intakeDataBottomLeft:string[] = ["date_of_jurisdictional", "jurisdictional_determination_type"];
  let intakeDataBottomRight:string[] = ["intake_severity_of_allegations"];
  let piDataTopLeft:string[] = ["pi_start_date", "pi_end_date"];
  let piDataTopRight:string[] = ["pi_assigned_to", "pi_severity_of_allegations", "pi_complexity"];
  let piDataSynopsis:string[] = ["pi_case_synopsis"]

  function makeLineBreaks(input:string){
    return input.replace("\n", "<BR /><BR />")
  }

  function makeDataLabel(o:string){
    return(
      <div className="dataHeader">{ props.caseDataLabels[o] }:</div>
    )
  }

  function makeDataField(o:string){
    if(!props.caseData[o]){
      return <div className="dataField">{getDataFieldEmptyState(o)}</div>
    }
    return(
      <div className="dataField">{ props.caseData[o] }</div>
    )
  }

  if(props.stageToPaint === "intro" && userCanEdit(props.caseData)){
    return (
      <div className="leftWrapper case_stage intro">
        <div className="wrapperHeader">{ props.caseData.case_status }, Currently Owned by you</div>
        <div className="caseDetails twoColumn">
          <div className="dataSet">
            { introDataLeft.map((item:string) => <div key={item}>{ makeDataLabel(item) }<MakeDataFieldOfTypes initialValue={ props.caseData[item]} field={ item } /></div> )}
          </div>
          <div className="dataSet">
            <div>{ makeDataLabel("case_stage") }{ makeDataField("case_stage") }</div>
            { introDataRight.map((item:string) => <div key={item}>{ makeDataLabel(item) }<MakeDataFieldOfTypes initialValue={ props.caseData[item]} field={ item } /></div> )}
          </div>
        </div>
      </div>
    )
  }
  else if(props.stageToPaint === "intro"){
    return (
      <div className="leftWrapper case_stage intro">
        <div className="wrapperHeader">{ props.caseData.case_status }, Currently Owned by { props.caseData.current_assignment }</div>
        <div className="caseDetails twoColumn">
          <div className="dataSet">
            { introDataLeft.map((item:string) => <div key={item}>{ makeDataLabel(item) }{ makeDataField(item) }</div> )}
          </div>
          <div className="dataSet">
            <div>{ makeDataLabel("case_stage") }{ makeDataField("case_stage") }</div>
            { introDataRight.map((item:string) => <div key={item}>{ makeDataLabel(item) }{ makeDataField(item) }</div> )}
          </div>
        </div>
      </div>
    )
  }
  else if(props.stageToPaint === "intake" && userCanEdit(props.caseData)){
    return (
      <div className="leftWrapper case_stage intake">
        <div className="wrapperHeader">Intake</div>
        <div className="caseDetails twoColumn">
          <div className="dataSet">
            { intakeDataTopLeft.map((item:string) => <div key={item}>{ makeDataLabel(item) }<MakeDataFieldOfTypes initialValue={ props.caseData[item]} field={ item } /></div> )}
          </div>
          <div className="dataSet">
            { intakeDataTopRight.map((item:string) => <div key={item}>{ makeDataLabel(item) }<MakeDataFieldOfTypes initialValue={ props.caseData[item]} field={ item } /></div> )}
          </div>
        </div>
        <div className="caseDetails">
          <div className="dataSet">
            { intakeDataMiddle.map((item:string) => <div key={item}>{ makeDataLabel(item) }<MakeDataFieldOfTypes initialValue={ props.caseData[item]} field={ item } /></div> )}
          </div>
        </div>
        <div className="caseDetails twoColumn">
          <div className="dataSet">
            { intakeDataBottomLeft.map((item:string) => <div key={item}>{ makeDataLabel(item) }<MakeDataFieldOfTypes initialValue={ props.caseData[item]} field={ item } /></div> )}
          </div>
          <div className="dataSet">
            <div>{ makeDataLabel("intake_assigned_to") }{ makeDataField("intake_assigned_to") }</div>
            { intakeDataBottomRight.map((item:string) => <div key={item}>{ makeDataLabel(item) }<MakeDataFieldOfTypes initialValue={ props.caseData[item]} field={ item } /></div> )}
          </div>
        </div>
        <div className="caseDetails actions spacing_top_large">
          <button className="button no_icon">Complete Intake</button>
        </div>
      </div>
    )
  }
  else if(props.stageToPaint === "intake"){
    return (
      <div className="leftWrapper case_stage intake">
        <div className="wrapperHeader">Intake</div>
        <div className="caseDetails twoColumn">
          <div className="dataSet">
            { intakeDataTopLeft.map((item:string) => <div key={item}>{ makeDataLabel(item) }{ makeDataField(item) }</div> )}
          </div>
          <div className="dataSet">
            { intakeDataTopRight.map((item:string) => <div key={item}>{ makeDataLabel(item) }{ makeDataField(item) }</div> )}
          </div>
        </div>
        <div className="caseDetails">
          <div className="dataSet">
            { intakeDataMiddle.map((item:string) => <div key={item}>{ makeDataLabel(item) }{ makeDataField(item) }</div> )}
          </div>
        </div>
        <div className="caseDetails twoColumn">
          <div className="dataSet">
            { intakeDataBottomLeft.map((item:string) => <div key={item}>{ makeDataLabel(item) }{ makeDataField(item) }</div> )}
          </div>
          <div className="dataSet">
            <div>{ makeDataLabel("intake_assigned_to") }{ makeDataField("intake_assigned_to") }</div>
            { intakeDataBottomRight.map((item:string) => <div key={item}>{ makeDataLabel(item) }{ makeDataField(item) }</div> )}
          </div>
        </div>
      </div>
    )
  }
  else if(props.stageToPaint === "pi" && userCanEdit(props.caseData)){
    return (
      <div className="leftWrapper case_stage pi">
        <div className="wrapperHeader">Preliminary Inquiry</div>
        <div className="caseDetails twoColumn">
          <div className="dataSet">
            { piDataTopLeft.map((item:string) => <div key={item}>{ makeDataLabel(item) }<MakeDataFieldOfTypes initialValue={ props.caseData[item]} field={ item } /></div> )}
          </div>
          <div className="dataSet">
            { piDataTopRight.map((item:string) => <div key={item}>{ makeDataLabel(item) }<MakeDataFieldOfTypes initialValue={ props.caseData[item]} field={ item } /></div> )}
          </div>
        </div>
        <div className="caseDetails">
          <div className="dataSet">
            { piDataSynopsis.map((item:string) => <div key={item}>{ makeDataLabel(item) }<MakeDataFieldOfTypes initialValue={ props.caseData[item]} field={ item } /></div> )}
          </div>
        </div>
        <div className="caseDetails actions spacing_top_large">
          <button className="button no_icon">Complete Preliminary Inquiry</button><button className="button no_icon spacing_left_medium">Send Back to Intake</button>
        </div>
      </div>
    )
  }
  else if(props.stageToPaint === "pi"){
    return (
      <div className="leftWrapper case_stage pi">
        <div className="wrapperHeader">Preliminary Inquiry</div>
        <div className="caseDetails twoColumn">
          <div className="dataSet">
            { piDataTopLeft.map((item:string) => <div key={item}>{ makeDataLabel(item) }{ makeDataField(item) }</div> )}
          </div>
          <div className="dataSet">
            { piDataTopRight.map((item:string) => <div key={item}>{ makeDataLabel(item) }{ makeDataField(item) }</div> )}
          </div>
        </div>
        <div className="caseDetails">
          <div className="dataSet">
            { piDataSynopsis.map((item:string) => <div key={item}>{ makeDataLabel(item) }{ makeDataField(item) }</div> )}
          </div>
        </div>
      </div>
    )
  }
  // must be investigation
  else if(userCanEdit(props.caseData)){
    return (
      <div className="leftWrapper case_stage investigation">
        <div className="wrapperHeader">Investigation</div>
        <div className="caseDetails twoColumn">

          <div className="dataSet">
            { intakeDataTopLeft.map((item:string) => <div key={item}>{ makeDataLabel(item) }<MakeDataFieldOfTypes initialValue={ props.caseData[item]} field={ item } /></div> )}
          </div>
          <div className="dataSet">
            { intakeDataTopRight.map((item:string) => <div key={item}>{ makeDataLabel(item) }<MakeDataFieldOfTypes initialValue={ props.caseData[item]} field={ item } /></div> )}
          </div>
        </div>
        <div className="caseDetails">
          <div className="dataSet">
            { intakeDataMiddle.map((item:string) => <div key={item}>{ makeDataLabel(item) }<MakeDataFieldOfTypes initialValue={ props.caseData[item]} field={ item } /></div> )}
          </div>
        </div>
        <div className="caseDetails actions spacing_top_large">
          <button className="button no_icon">Complete Investigation</button><button className="button no_icon spacing_left_medium">Send Back to PI</button><button className="button no_icon spacing_left_medium">Send Back to Intake</button>
        </div>
      </div>
    )
  }
  else {
    return (
      <div className="leftWrapper case_stage investigation">
        <div className="wrapperHeader">Investigation</div>
        <div className="caseDetails twoColumn">

          <div className="dataSet">
            { intakeDataTopLeft.map((item:string) => <div key={item}>{ makeDataLabel(item) }{ makeDataField(item) }</div> )}
          </div>
          <div className="dataSet">
            { intakeDataTopRight.map((item:string) => <div key={item}>{ makeDataLabel(item) }{ makeDataField(item) }</div> )}
          </div>
        </div>
        <div className="caseDetails">
          <div className="dataSet">
            { intakeDataMiddle.map((item:string) => <div key={item}>{ makeDataLabel(item) }{ makeDataField(item) }</div> )}
          </div>
        </div>
      </div>
    )
  }
}



interface FileList {
  name: string,
  mime_type: string,
  date_uploaded: string,
  uploaded_by: string,
  case_stage: string,
  tags: string
}

let caseFileList:FileList[] = [
  { name: "Updated document name for case.pdf", mime_type: "application/pdf", date_uploaded: "May 3, 2021", uploaded_by: "Mary Noname", case_stage: "Intake", tags: "Documentary Evidence" },
  { name: "IMG_0282.jpg", mime_type: "image/jpeg", date_uploaded: "May 7, 2021", uploaded_by: "Mary Noname", case_stage: "Intake", tags: "Documentary Evidence" },
  { name: "Photo of Claimant.jpg", mime_type: "image/jpeg", date_uploaded: "May 9, 2021", uploaded_by: "Barry Madeup", case_stage: "Preliminary Inquiry", tags: "Documentary Evidence" },
  { name: "Video recording submitted via iPhone.mov", mime_type: "video/mpeg", date_uploaded: "May 14, 2021", uploaded_by: "Mary Noname", case_stage: "Investigation", tags: "Documentary Evidence" },
  { name: "Updated document name for case.docx", mime_type: "application/msword", date_uploaded: "May 14, 2021", uploaded_by: "Mary Noname", case_stage: "Investigation", tags: "Transcript" }
]



function CaseFlags(props:any){

  function getWrapperClass(attr:string){
    if(attr === "alert"){
      return "wrapperAlert";
    }
    return "wrapperNotice";
  }

  if(props.caseData.flags.length){
    return(
      <div className="flagsWrapper rightWrapper">
        <div className="wrapperHeader">Flags</div>
        { props.caseData.flags.map ( (flag:FlagDetail, i:number) => <div key={ "flag" + i } className={ getWrapperClass(flag.alert) }>{ flag.flag } { flag.date }</div> ) }
      </div>
    )
  }
  return(
    <div className="flagsWrapper rightWrapper">
      <div className="wrapperHeader">Flags</div>
      <div className="wrapperNotice">There are no flags at this time</div>
    </div>
  )
}

function RelatedCases(props:any){
  if(props.caseData.related_cases.length){
    return(
      <div className="relatedCasesWrapper rightWrapper">
        <div className="wrapperHeader twoColumn">
          <div>Related Cases</div>
          <div className="wrapperHeaderAction">Add Case</div>
        </div>
        { props.caseData.related_cases.map ( (casestring:string) => <div key={casestring} className="relatedCases twoColumn"> <div className="relatedCase" onClick={ () => document.location.href = "/case/" + casestring }>{ casestring }</div> <div className="relatedCaseActions"> <div className="relatedCaseAction">Edit</div> <div className="relatedCaseAction">Remove</div> </div> </div>)}
      </div>
    )
  }
  return(
    <div className="relatedCasesWrapper rightWrapper">
      <div className="wrapperHeader twoColumn">
        <div>Related Cases</div>
        <div className="wrapperHeaderAction">Add Case</div>
      </div>
      <div className="relatedCases twoColumn">
        <div className="relatedCase">2020-0289</div>
        <div className="relatedCaseActions">
          <div className="relatedCaseAction">Edit</div>
          <div className="relatedCaseAction">Remove</div>
        </div>
      </div>
    </div>
  )
}

function CaseTasks(props:any){
  if(props.caseData.tasks.length){
    return(
      <div className="tasksWrapper rightWrapper">
        <div className="wrapperHeader">
          <div>Tasks</div>
        </div>
        { props.caseData.tasks.map ( (task:TaskStructure, i:number) => <div key={"task" + i} className="task twoColumn"><div className="taskDetail">{ task.detail }, assigned to { task.assigned_to }</div><div className="taskDueDate hasCallout">{ task.due_date }</div></div> ) }
        <div className="task twoColumn createTask">
          <div className="taskDetail">Add New Task</div>
        </div>
      </div>
    )
  }
  return(
    <div className="tasksWrapper rightWrapper">
      <div className="wrapperHeader">
        <div>Tasks</div>
      </div>
      <div className="task twoColumn createTask">
        <div className="taskDetail">Add New Task</div>
      </div>
    </div>
  )
}



function CaseDetail(props:any){

  if(props.caseData.case_stage === "Investigation"){
    return (
      <div className="sectionWrapper">
        <section className="colLeft caseDetailContainer">
          <CaseStage stageToPaint="intro" caseDataLabels={ caseDataLabels } caseData={ props.caseData } />
          <CaseStage stageToPaint="intake" caseDataLabels={ caseDataLabels } caseData={ props.caseData } />
          <CaseStage stageToPaint="pi" caseDataLabels={ caseDataLabels } caseData={ props.caseData } />
          <CaseWidgetIntake editable="true" caseDataLabels={ caseDataLabels } caseData={ props.caseData } />
          <CaseWidgetCurrentDisposition caseDataLabels={ caseDataLabels } caseData={ props.caseData } />
        </section>
        <section className="colRight">
          <CaseFlags caseData={ props.caseData } />
          <CaseParties caseData={ props.caseData } />
          <CaseAllegations caseData={ props.caseData } />
          <RelatedCases caseData={ props.caseData } />
          <CasePersonnel caseData={ props.caseData } />
          <CaseIRs irs={ getIR() } />
          <CaseHistory caseData={ props.caseData } />
          <CaseTasks caseData={ props.caseData } />
        </section>
      </div>
    )
  }
  else if(props.caseData.case_stage === "Preliminary Inquiry"){
    return (
      <div className="sectionWrapper">
        <section className="colLeft caseDetailContainer">
          <CaseStage stageToPaint="intro" caseDataLabels={ caseDataLabels } caseData={ props.caseData } />
          <CaseStage stageToPaint="intake" caseDataLabels={ caseDataLabels } caseData={ props.caseData } />
          <CaseStage stageToPaint="pi" caseDataLabels={ caseDataLabels } caseData={ props.caseData } />
          <CaseWidgetCurrentDisposition caseDataLabels={ caseDataLabels } caseData={ props.caseData } />
        </section>
        <section className="colRight">
          <CaseFlags caseData={ props.caseData } />
          <CaseParties caseData={ props.caseData } />
          <CaseAllegations caseData={ props.caseData } />
          <RelatedCases caseData={ props.caseData } />
          <CasePersonnel caseData={ props.caseData } />
          <CaseIRs irs={ getIR() } />
          <CaseHistory caseData={ props.caseData } />
          <CaseTasks caseData={ props.caseData } />
        </section>
      </div>
    )
  }
  else{  // just Intake
    return (
      <div className="sectionWrapper">
        <section className="colLeft caseDetailContainer">
          <CaseStage stageToPaint="intro" caseDataLabels={ caseDataLabels } caseData={ props.caseData } />
          <CaseStage stageToPaint="intake" caseDataLabels={ caseDataLabels } caseData={ props.caseData } allowEdit={ true } />
        </section>
        <section className="colRight">
          <CaseFlags caseData={ props.caseData } />
          <CaseParties caseData={ props.caseData } />
          <CaseAllegations caseData={ props.caseData } />
          <RelatedCases caseData={ props.caseData } />
          <CasePersonnel caseData={ props.caseData } />
          <CaseIRs irs={ getIR() } />
          <CaseHistory caseData={ props.caseData } />
          <CaseTasks caseData={ props.caseData } />
        </section>
      </div>
    )
  }
}



function LayoutIR(props:any){
  let irs = [...props.irs];
  let ir = irs[props.i - 1];
  let reporter = ir.parties[ir.reporterID];

  for(let x=0;x< ir.parties.length; x++){
    ir.parties[x].contact = {phone: ir.parties[x].phone || "", email: ir.parties[x].email || "" };
  }

  function DataSet(props:any){
    return (
      <>
      <div className="dataHeader">{ props.header }:</div>
      <div className="dataField">{ props.field }</div>
      </>
    )
  }

  function toTitlecase(s:string){
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  if(typeof ir === typeof {}){
    return(
      <section className="irWrapper">

        <div className="dataSet">
          <div className="dataHeader">Incident Report #:</div>
          <div className="wrapperHeader">{ ir.ir_id }</div>
          <DataSet header="Reporter name" field={ reporter.name } />
          <DataSet header="Reporter role" field={ toTitlecase(reporter.role) } />
          <DataSet header="Reporter nickname" field={ reporter.nickname } />
          <DataSet header="Reporter phone" field={ reporter.phone } />
        </div>
        <table className="dashboard_table dashboard_table_clean spacing_top_large">
          <thead>
            <tr className="tablehead">
              <th>Name</th>
              <th>Relationship</th>
              <th>Phone #</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
          { ir.parties.map( (p:PartyDetail, i:number) => (
              <tr className={ p.annotation } key={ p.name }>
                <td className="party_name">{ p.name }</td>
                <td className="party_relationship">{ p.role }</td>
                <td className="fixedWidthTD30P">
                  { p.phone }
                </td>
                <td className="fixedWidthTD30P">
                  { p.email }
                </td>
              </tr>
            ))
          }
          </tbody>
          { props.showAdd ? (
            <>
              <tr className="tr_separator">
                <td colSpan={5}>Add a New Party</td>
              </tr>
              <NewParty parties={props.caseData.parties} />
            </>
          ) : "" }

        </table>

      </section>
    )
  }
  else{
    return(
      <></>
    )
  }
}

function CaseIRs(props:any){
  const [modalOpen,setModalOpen] = useState(0);
  const [modalShowsAdd, setModalShowsAdd] = useState(false);

  if(props.irs.length === 0){
    return(
      <div className="incidentWrapper rightWrapper">
        <div className="wrapperHeader">Incident Report</div>
        <div className="wrapperNotice">There are no associated Incident Reports</div>
      </div>
    )
  }
  else if(modalOpen > 0){
    return(
      <>
        <div className="modalOverlay">
          <div className="modalContent">
            <button className="modalClose" onClick={ (e) => setModalOpen(0)}></button>
            <LayoutIR irs={ props.irs } i={ modalOpen }/>
          </div>
        </div>
        <div className="incidentsWrapper rightWrapper">
          <div className="wrapperHeader">Incident Report</div>
          { props.irs.map ( (ir:any, i:number) => <div key={ "ir" + i } className="incident" onClick={ (e) => setModalOpen(i+1)}>
              { ir.ir_id } ({ ir.incident_date })
          </div> ) }
        </div>
      </>
    )
  }
  else{
    return(
      <div className="incidentsWrapper rightWrapper">
        <div className="wrapperHeader">Incident Report</div>
        { props.irs.map ( (ir:any, i:number) => <div key={ "ir" + i } className="incident" onClick={ (e) => setModalOpen(i+1)}>
            { ir.ir_id } ({ ir.incident_date })
        </div> ) }
      </div>
    )
  }
}






function CaseApp(props:any) {
  let case_id = CaseNumber();
  let case_number = parseInt(case_id.replace("2021-",""), 10);
  let case_toggle = 0;
  if(case_number % 2 === 0) {
    case_toggle = 1;
  }
  let fauxCaseData = case_number === 6699 ? makeCaseData(6699) : makeCaseData(case_toggle);
  return(
    <span>
      <CaseCap />
      <CaseHeader caseData={ fauxCaseData } />
      <CaseDetail caseData={ fauxCaseData } />
      <FileManagerComponent fileList={ caseFileList } type="cases" />
    </span>
  );
}


export default CaseApp;
