import React from 'react';
import { Scatter } from 'react-chartjs-2';
import ScatterCaseData from './fakecasedata.json'

const rand = () => Math.round(Math.random() * 20 - 10);




const data = {
  datasets: [
    {
      label: 'Open Cases',
      data: ScatterCaseData,
      backgroundColor: 'rgba(255, 99, 132, 1)',
      displayColors: false,
      padding: {
        top: 30, bottom: 30, left: 15, right: 15
      },
      pointBackgroundColor: function(context:any) {
        var index = context.dataIndex;
        var value = context.dataset.data[index].x;
        if(value < 30){
          return "red";
        }
        else if(value < 90){
          return "blue";
        }
        else if(value < 200){
          return "green"
        }
        else{
          return "orange";
        }
      }
    },
  ],
};

const options = {
  font: {
    family: 'Roboto'
  },
  scales: {
    yAxes: {
      ticks: {
        beginAtZero: true,
        font: {
          weight: "100"
        }
      },
      title: {
        display: true,
        text: "Incident Age (in Days)",
        align: "start",
        font: {
          weight: "600",
          size: 14
        }
      },
    },
    xAxes: {
      ticks: {
        font: {
          weight: "100"
        }
      },
      title: {
        display: true,
        text: "Case Age (in Days)",
        align: "start",
        font: {
          weight: "600",
          size: 14
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function(context:any) {
          var index = context.dataIndex;
          var value = context.dataset.data[index].x;
          let case_stage:string = "";
          if(value < 30){
            case_stage = "Intake";
          }
          else if(value < 90){
            case_stage = "Preliminary Inquiry";
          }
          else if(value < 200){
            case_stage = "Investigation";
          }
          else{
            case_stage = "Disposition"
          }
          var label = ["Case: " + context.raw.case_number, "Respondent: " + context.raw.name, "(" + case_stage + ")", " ", "Click for details"];
          return label;
        },
        backgroundColor: function(context:any){
          return {
            backgroundColor: "white"
          }
        },
        onHover: (event:any, chartElement:any) => {
          console.log(event);
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        }
      },
      displayColors: false,
      padding: {
        top: 12, bottom: 12, left: 15, right: 15
      },
      backgroundColor: "white",
      titleColor: "black",
      bodyColor: "black",
      footerColor: "black",
      borderColor: "black",
      borderWidth: 1,
      cornerRadius: 0,
      mode: "point",
      caretPadding: 10,
      displayColor: false
    }
  },
  onClick: function(evt:any, element:any) {
    if(element.length > 0) {
      let clickedObject = element[0].element.$context.raw;
      document.location.href = "/case/" + clickedObject.case_number;
    }
  }
};

const ScatterChart = () => (
  <Scatter data={ data } options={ options } />
);

export default ScatterChart;
