function Notifications(props: any) {
  return (
    <section>
      <div className="text">
        <div className="section_title">Notifications</div>
        <div className="section_caption">Last updated yesterday</div>
      </div>
    </section>
  );
}


export default Notifications;
